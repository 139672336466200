import { Component, OnInit, ViewChild } from '@angular/core';
import { ButtonMenuComponent } from '@cop/design/components';
import { DialogService } from '@src/app/cprs/modules/dialogs/services/dialog.service';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { CprsTableGrid } from '../../../table/models/cprs-table-grid.interface';
import { SavedFeatureService } from '../../services/saved.feature.service';
import { DownloadDialogComponent } from '@src/app/cprs/modules/dialogs/components/download-dialog/download-dialog.component';
import { CprsDialogComponent } from '@src/app/cprs/modules/dialogs/components/cprs-dialog/cprs-dialog.component';
import { CreateUserSavedSearchResponse } from '../../services/user.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CprsService } from '@src/app/cprs/services/cprs.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cprs-module-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.scss'],
})
export class SavedSearchesComponent implements OnInit {
  @ViewChild('actionsMenuButton')
  actionsMenuButton!: ButtonMenuComponent;

  public table = new CprsTableGrid('recentSearchesTable', 'List of Recent Searches', [
    {
      label: 'Search term',
      columnKey: 'search_term',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Field heading',
      columnKey: 'field_heading',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Filters added',
      columnKey: 'filters_added',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Number of results',
      columnKey: 'number_of_results',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
      pipe: 'number'
    },
    {
      label: 'Date saved',
      columnKey: 'date_saved',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
  ]);

  public mode: 'grid' | 'table' = 'table';

  public totalElements: number;

  public data: any[] = [];

  constructor(
    public savedFeatureService: SavedFeatureService,
    public cdSelectionService: CdSelectionService,
    public dialogService: DialogService,
    public cprsService: CprsService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.cdSelectionService.deselectAllByKey('saved_searches');

    this.loadTable();
  }

  loadTable() {
    this.savedFeatureService.getSearches().subscribe(searches => {
      this.data = searches.map((r) => this.getTableData(r)).reverse();

      this.totalElements = searches.length;

      const saved_searches: UntypedFormGroup = this.cdSelectionService.getSelectionGroup('saved_searches');
      const saved_searches_page: UntypedFormControl = saved_searches.get('page') as UntypedFormControl;
      const updatePage = saved_searches_page.value;
      updatePage.totalElements = searches.length;

      saved_searches_page.setValue(updatePage);
      this.cprsService.getLoadingSubject('saved_searches').next(false);
    })
  }

  email(): void {
    const records = this.cdSelectionService.getSelectedByKey('saved_searches');

    const simple_records = records.map((rs) => {
      return {
        searchLink: rs.url.value,
        searchTitle: rs.search_term.value,
      };
    });

    this.dialogService.open(CprsDialogComponent, {
      data: { title: 'Title', responseType: 'saved_or_recent', records: simple_records },
    });

    this.actionsMenuButton.closeDropdown();
  }

  pageChange($event: any) {
    const page: UntypedFormControl = this.cdSelectionService.getSelectionGroup('saved_searches').get('page');
    page.setValue($event);
  }

  download() {
    const records = this.cdSelectionService.getSelectedByKey('saved_searches');

    const simple_records = records.map((rs) => {
      return {
        searchLink: rs.url.value,
        searchTitle: rs.search_term.value,
      };
    });

    this.dialogService.open(DownloadDialogComponent, {
      data: { title: 'Title', responseType: 'search_results', control_numbers: simple_records },
    });

    this.actionsMenuButton.closeDropdown();
  }

  clear() {
    // this.savedFeatureService.clearSearches(this.cdSelectionService.getSelectedByKey('saved_searches'));
    const selected: any[] = this.cdSelectionService.getSelectedByKey('saved_searches');
    this.cprsService.getLoadingSubject('saved_searches').next(true);
    this.savedFeatureService.removeSearches(selected.map(s => s.url.value)).subscribe(() => {
      this.loadTable();
    });
    this.cdSelectionService.deselectAllByKey('saved_searches');
    this.actionsMenuButton.closeDropdown();
  }

  getTableData(response: CreateUserSavedSearchResponse) {
    const saved_search = response.data;
    return {
      search_term: {
        value: saved_search.search_term,
        url: saved_search.url,
      },
      field_heading: {
        value: saved_search.field_heading,
      },
      filters_added: {
        value: saved_search.filters_added,
      },
      number_of_results: {
        value: saved_search.number_of_results,
      },
      date_saved: {
        value: this.datePipe.transform(saved_search.timestamp, 'yyyy-MM-dd hh:mm a'),
      },
      url: {
        value: saved_search.url,
      },
    };
  }
}
