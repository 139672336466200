<div class="page-not-found">
  <h1>404</h1>
  <h2>The page for which you are looking was not found.</h2>

  <div class="page-not-found-content flex">
    <div class="col">
      <div>
        Please feel free to run your search again.
        <br />You can search by Copyright number, Names, Title, Keyword, and more.
      </div>

      <div class="landing-search">
        <div class="basic-search">
          <div class="row">
            <!-- Convert to cd3 component -->
            <!-- <cds-text-input
              id="search"
              name="search"
              placeholder="Search Copyright numbers, Name, Title, and more."
              (keyup.enter)="search()"
              [(ngModel)]="searchCriteria.query"
              type="text"
            ></cds-text-input> -->
          </div>
          <div class="landing-advanced-search">
            <a href="/advanced-search" (click)="$event.preventDefault(); advancedSearch()">Advanced Search</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div>Or visit our Help section to learn more about search features and functionality.</div>

      <div>
        <!-- <app-cds-icon iconSize="xl" [irsIcon]="['fas', 'question-circle']"></app-cds-icon> -->
      </div>

      <div>
        <p>You can also go back to the <a href="/">Public Records System home page</a>.</p>

        <p>
          If you would like to reach out to us and provide feedback, please contact
          <a href="mailto:publicrecords@copyright.gov?subject=Feedback on Public Records System"
            >publicrecords&#64;copyright.gov</a
          >.
        </p>
      </div>
    </div>
  </div>
</div>
