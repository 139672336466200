export const environment = {
  envName: '',
  enableIdpHintWorkaround: false,
  profileScope: '',
  production: false,
  remitterServiceUrl: '/Platform/RemitterOrg',
  searchServiceUrl: '',
  userServiceUrl: '',
  templatingServiceUrl: '',
  enableErrorDialogs: false,
  sentryDsn: '',
  defaultRecordSize: '10',
  internal: false,
  systemXri: '',
  idpHint: '',
  idpHintParam: ''
};
