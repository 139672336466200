<div class="session-expired">
  <h1 class="col-10 display-3">Your session has expired due to inactivity</h1>

  <div class="session-expired-content flex">
    <div class="col">
      <div>
        Please feel free to run your search again.
        <br />You can search by Copyright number, Names, Title, Keyword, and more.
      </div>

      <div class="landing-search">
        <div class="basic-search">
          <!-- Convert to cd3 component -->
          <!-- <cds-text-input
            id="search"
            name="search"
            placeholder="Search Copyright numbers, Name, Title, and more."
            (keyup.enter)="search()"
            [(ngModel)]="searchCriteria.query"
            type="text"
          ></cds-text-input> -->
          <div class="landing-advanced-search">
            <a href="/advanced-search" (click)="$event.preventDefault(); advancedSearch()">Advanced Search</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div>Or visit our Help section to learn more about search features and functionality.</div>

      <div>
        <!-- Convert to cd3 component -->
        <!-- <app-cds-icon iconSize="xl" [irsIcon]="['fas', 'question-circle']"></app-cds-icon> -->
      </div>

      <div>
        <p>You can also go back to the <a href="/">Public Records System home page</a>.</p>

        <p>
          If you would like to reach out to us and provide feedback, please contact
          <a href="mailto:publicrecords@copyright.gov?subject=Feedback on Public Records System"
            >publicrecords&#64;copyright.gov</a
          >.
        </p>
      </div>
    </div>
  </div>
</div>
