import { VoyagerProperty } from '../../../services/transformer.service';
import { Voyager } from '../voyager.class';

export class VoyagerAcquisition extends Voyager {
    public schema: VoyagerProperty[] = [
        this.copyright_data.acquisition_number,
        this.copyright_data.type_of_acquisition,
        this.notes.catalog_of_copyright_entries_part_codes,
        this.dates.latest_transaction_date_and_time,
        this.administrative.copyright_count_category,
        this.administrative.service_request_row_identifier,
        this.copyright_data.service_request_number,
        this.notes.terms_of_use,
        // missing Date Record Entered on File
        this.notes.record_created_by,
        this.administrative.record_origin_code,

        // titles
        this.titles.title,
        this.titles.other_titles,
        this.titles.other_titles_two,
        this.titles.appears_in,
        this.titles.title_translated,

        // biblographic description
        this.description.imprints,
        this.description.publisher_number,
        this.description.edition,
        this.description.description,
        this.titles.series,
        this.titles.series_list,

        // standard identifiers
        this.standard_identifiers.isbn,
        this.standard_identifiers.issn_list,
        this.standard_identifiers.isrc,
        this.standard_identifiers.ismn,
        this.standard_identifiers.upc,
        this.standard_identifiers.ean,
        this.standard_identifiers.ipn_number,
        this.standard_identifiers.record_id,
        this.standard_identifiers.system_control_number,
        this.standard_identifiers.control_number_identifier,
        this.standard_identifiers.originating_control_number,

        // links?
        this.notes.notes,
        this.description.publisher_name,
        this.description.link,
        this.claim_description.place_of_publication_code, // MISSING
        this.description.frequency_of_publication,
        this.description.former_publication_frequency,
        this.titles.selection_number_of_copies,
        // main_entry_organization_name missing
        this.serial_information.serial_description_based_on_note,
        this.administrative.record_transcribing_agency,
        this.administrative.record_cataloging_agency,
        this.names.main_entry_personal_name,
        this.notes.selection_notes,
        this.description.copies_retained,
        this.description.copies_deposited,
        this.description.copies_date_of_receipt,

        // deposits information
        this.deposit_information.deposit_information,

        // names
        this.names.names,

        // parties
        this.parties.claimants_list
    ]
}
