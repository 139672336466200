import { APP_BASE_HREF, CommonModule, DatePipe, PlatformLocation } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app/app.component';
import { DesignModule } from '@cop/design';
import { Idle, NgIdleModule } from '@ng-idle/core';
import { lookupKeycloakConfig, lookupURL, URL_LOOKUP, UrlType } from '@app/cprs/consts/url.constants';
// import { SentryErrorHandler } from '@shared/error-handlers/sentry.error-handler';
import { SharedModule } from '@shared/shared.module';
import { environment } from '@src/environments/environment';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';

import { CprsModule } from './cprs/cprs.module';
import { IdleService } from './cprs/modules/system/services/idle.service';
import { EnvServiceProvider } from './cprs/modules/shared/services/env/env.service.provider';

import { GlobalCacheConfig, LocalStorageStrategy } from 'ts-cacheable';
import { AUTHENTICATION_SERVICE, DesignServicesModule } from '@cop/design/services';
import { DesignAuthModule } from '@cop/design/auth';
import { CustomAuthGuardService } from './cprs/modules/system/guards/custom-auth-guard.service';
import { SentryErrorHandler } from '@src/app/cprs/modules/system/services/sentry.error-handler';
import { keycloakInitializer } from '@src/kc.init';
import { EnvService } from './cprs/modules/shared/services/env/env.service';
import { FeatureFlagService } from './cprs/modules/shared/services/feature-flag/feature-flag.service';
import { DisclaimerModule, DISCLAIMER_CONFIG } from '@cop/disclaimer';
import { appDisclaimerConfig } from '@src/app/disclaimer-config';

GlobalCacheConfig.storageStrategy = LocalStorageStrategy;

export function initializeIdle(idleService: IdleService) {
  return () => idleService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    RouterModule,
    SharedModule,
    NgIdleModule.forRoot(),
    /* TODO: move to layout.module */
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    DesignModule,
    DesignAuthModule.forRoot({
      disableKeyCloak: true
    }),
    CprsModule,
    DesignServicesModule.forRoot({
      env: environment,
      urlConstants: URL_LOOKUP,
    }),
    DisclaimerModule
  ],
  exports: [],
  providers: [
    {
      provide: DISCLAIMER_CONFIG,
      useValue: appDisclaimerConfig,
    },
    // { provide: DISCLAIMER_CONFIG, useValue: { disableGuards: true } as DisclaimerConfig },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: AUTHENTICATION_SERVICE,
      useExisting: CustomAuthGuardService, // or AuthguardInternalService or ProfileService
    },

    {
      provide: 'lookupURL',
      useValue: lookupURL,
    },
    {
      provide: 'UrlType',
      useValue: UrlType,
    },
    {
      provide: 'lookupKeycloakConfig',
      useValue: lookupKeycloakConfig,
    },
    {
      provide: 'env',
      useValue: environment,
    },
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService, EnvService, FeatureFlagService],
    },
    IdleService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeIdle,
      deps: [IdleService, Router, Idle],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    EnvServiceProvider,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule { }
