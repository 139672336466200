<!--Was default, no longer valid-->
<cd-modal [(opened)]="opened">
  <div title>Email selected record(s)</div>
  <div body>
    <div class="container">
      <div class="row">
        <div class="col mb-4">
          <form [formGroup]="formGroup">
            <cd-input
              [label]="'Email'"
              [errorMessage]="error_message"
              [type]="'email'"
              formControlName="email_address"
            ></cd-input>
          </form>

          <div *ngIf="hasAcquisitionsOnDrv || hasAcquisitionsSearchResults" class="my-2 cd-text-error">
            You are not able to send an acquisition record
          </div>
        </div>
      </div>
    </div>
  </div>
  <div footer>
    <cd-button [mode]="'secondary'" class="pe-3" (btnClick)="close()">Cancel</cd-button>
    <cd-button [mode]="'primary'" (btnClick)="send()">Send</cd-button>
  </div>
</cd-modal>

<cd-modal alert="success" [(opened)]="successModal">
  <div title>Success!</div>
  <div body>
    Your email has been successfully sent.

    <div *ngIf="hasAcquisitionsOnDrv || hasAcquisitionsSearchResults" class="my-2 cd-text-error">
      You are not able to send an acquisition record
    </div>
  </div>
  <div footer>
    <cd-button [mode]="'primary'" (btnClick)="close()">Close</cd-button>
  </div>
</cd-modal>
