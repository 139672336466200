<cprs-base-page [hideSearch]="true">
  <div class="my-4">
    <h1 class="display-3">Record Editor</h1>
  </div>

  <div class="mb-2">
    <div class="display-7">Locate record</div>
    <div class="col-6">
      <form class="row g-3" [formGroup]="search2Form" (ngSubmit)="search()" novalidate autocomplete="off">
        <div class="col-12">
          <cd-search [queryControlName]="'query'" [optionsControlName]="'keywords'" [options]="recordEditorSearchOptions"
          > </cd-search>
        </div>
      </form>
    </div>
    <hr class="mb-1">
  </div>

  <cprs-errors [key]="'record_editor'"></cprs-errors>

  <ng-container *ngIf="noResults">
      <div>
        <div class="my-1 display-5">
          No search results were found
        </div>
        <div class="display-5">Search suggestion</div>
        <ul class="my-2 mx-4 list-style">
          <li>Check the Copyright number or Bib ID</li>
        </ul>
      </div>
  </ng-container>

</cprs-base-page>
