import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';

@Component({
  selector: 'cprs-mega-mini-menu',
  templateUrl: './mega-mini-menu.component.html',
  styleUrls: ['./mega-mini-menu.component.scss'],
})
export class MegaMiniMenuComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});

  @Input() selected: { text: string; value: string } | null = null;

  @Output() selectedChange = new EventEmitter<{ text: string; value: string }>();

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() onSelect = new EventEmitter();

  @ViewChild('cdSelectID', { static: true }) cdSelectID: ElementRef | undefined;

  public state = false;

  public options: any;

  public field_heading_options: { text: string; value: string }[] = [];

  constructor(public stateService: StateService, public envService: EnvService) {
    this.options = this.stateService.mega_mini_menu_options;

    if (!this.envService.internal) {
      this.options.copyright_numbers = this.options.copyright_numbers.filter(
        (o: { text: string; value: string }) => o.value !== 'sr_numbers',
      );
      this.options.other = this.options.other.filter(
        (o: { text: string; value: string }) => o.value !== 'deposit_tracking_number',
      );
      this.options.other = this.options.other.filter(
        (o: { text: string; value: string }) => o.value !== 'deposit_box_number',
      );
      this.options.other = this.options.other.filter(
        (o: { text: string; value: string }) => o.value !== 'deposit_dispatch_custody',
      );
    }
  }

  ngOnInit(): void {
    this.field_heading_options = this.selected == null ? [] : [this.selected];

    if (!this.selected) {
      this.selected = { text: 'Keyword', value: 'keyword' };
    }
  }

  // eslint-disable-next-line no-empty-function

  public select(selectedValue: string) {
    const optionText: any = Array.from(this.cdSelectID?.nativeElement)?.find((option: any) => option.id === selectedValue);
    const filterOptionObj: { text: string; value: string } = { value: selectedValue, text: optionText?.label }
    const option = filterOptionObj;
    this.selected = option;
    this.state = false;
    this.field_heading_options = [option];
    this.selectedChange.emit(option);
    this.onSelect.emit(option);
  }
}
