import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { Page, PageImpl } from '@cop/design/services';
import { BehaviorSubject } from 'rxjs';
import { CdTableData, CprsTableGrid } from '../../models/cprs-table-grid.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { ParametersService } from '@src/app/cprs/services/parameters.service';
import { SortEvent } from '@cop/design/table';

@Component({
  selector: 'app-cprs-grid',
  templateUrl: './cprs-grid.component.html',
  styleUrls: ['./cprs-grid.component.scss']
})
export class CprsGridComponent implements OnInit, OnChanges {
  first: number = 0;
  @Input() headerTemplateRef: TemplateRef<any>;

  @Input() sideColumnRef: TemplateRef<any>;

  @Input() interface: CprsTableGrid;

  @Input() mode: 'grid' | 'table' = 'grid';

  @Input() sortType: 'client' | 'server' = 'client';

  @Input() pagingType: 'client' | 'server' = 'client';

  @Input() selectionKey: string;

  @Input() content: any[] = [];

  @Input() totalElements: number;

  @Input() showIndexColumn: boolean = true;
  @Input() showSelectControls: boolean = true;

  @Input() serverPage: BehaviorSubject<Page<any>>;

  @Output() sortChange = new EventEmitter<SortEvent>();

  @Output() pageChange = new EventEmitter<Page<any>>();

  @Output() viewModeChange = new EventEmitter<'grid' | 'table'>();

  public selectionForm: UntypedFormGroup;

  @Input() clientPage: BehaviorSubject<Page<any>>;

  @Input() fakePage = PageImpl.empty();

  columnForm: UntypedFormGroup;
  gridColumnForm: UntypedFormGroup;
  tableColumnForm: UntypedFormGroup;

  visibleColumns: string[];
  modeColumns: any[];

  public manual = {
    pageStart: 0,
    pageEnd: 0,
    total: 0,
  };

  constructor(public router: Router, public selectionService: CdSelectionService, public parameterService: ParametersService) {
    this.fakePage.size = 10;
    this.fakePage.totalPages = 1;
    this.fakePage.first = true;
    this.fakePage.isLoaded = true;
    this.fakePage.numberOfElements = this.fakePage.size;
  }

  /*
    Prepare table data based on the interfae
      - CD3.0 requires a formgroup and visible columns
  */
  ngOnInit(): void {
    if (this.interface) {
      this.updateColumns();
    }

    if (this.selectionKey) {
      this.selectionForm = this.selectionService.getSelectionGroup(this.selectionKey);
    }
    this.clientPage.subscribe((page) => {
      this.updatePagingDisplay(page);
      this.pageChange.emit(page);
    });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const link = (event.target as HTMLElement).querySelector('.link') as HTMLAnchorElement;
      if (link) {
        window.location.href = link.href;
      }
    }
  }

  get $page() {
    return this.pagingType === 'client' ? this.clientPage : this.serverPage;
  }

  updatePagingDisplay(page: Page<any>, constant = 0) {
    // TODO MANUAL
    this.manual.total = page.totalElements;
    if (page.number === 0) {
      this.manual.pageStart = 1;
    } else {
      this.manual.pageStart = (Number(page.number) + constant) * Number(page.size) + 1;
    }
    this.manual.pageEnd = (Number(page.number) - constant) * Number(page.size) + Number(page.size);
    if (this.manual.total <= this.manual.pageEnd) {
      this.manual.pageEnd = this.manual.total;
    }

    this.manual.pageEnd = 12;
  }

  updateColumns() {
    const formGroup = new UntypedFormGroup({});
    const gridColumns = this.interface.columns
      .filter((c) => c.visibility.grid && c.visibility.visible)
      .map((c) => c.columnKey);
    this.interface.columns
      .filter((c) => c.visibility.grid)
      .forEach((c) => formGroup.addControl(c.columnKey, new UntypedFormControl(c.visibility.visible)));
    this.modeColumns = this.interface.columns.filter((c) => c.visibility.grid);

    this.gridColumnForm = formGroup;
    this.visibleColumns = gridColumns;

    this.gridColumnForm.valueChanges.subscribe((columns) => {
      this.visibleColumns = Object.keys(columns).filter((col) => columns[col] === true);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['fakePage']) {
      this.fakePage = changes['fakePage']?.currentValue
      const pageStart = this.fakePage.number * this.fakePage.size + 1;
      if (this.fakePage.totalElements < pageStart) {
        this.fakePage.number = this.fakePage.number - 1 > 0 ? this.fakePage.number - 1 : 0;
      }
      if (this.totalElements <= this.fakePage.numberOfElements) {
        this.fakePage.numberOfElements = this.fakePage.totalElements;
      }
      this.clientPage?.next(this.fakePage);
      this.serverPage?.next(this.fakePage);
    }

    if (changes && changes['mode']) {
      this.updateColumns();
    }
  }

  sortTable($event: any) {
    if (this.sortType === 'client') {
      this.genericSort($event);
    } else {
      this.sortChange.emit($event);
    }
  }

  get columnKeys(): string[] {
    return Object.keys(this.columnForm.controls);
  }

  getIndex(index: number) {
    return this.fakePage.number * this.fakePage.size + index + 1;
  }

  getColumnLabel(columnKey: string) {
    return this.interface.columns.find((c) => c.columnKey === columnKey)?.label;
  }

  public genericSort($event: SortEvent) {
    this.content.sort((a: CdTableData, b: CdTableData) => {
      let comparison = 0;

      const valueA = a[$event.name]['value'];
      const valueB = b[$event.name]['value'];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // compare string values
        comparison = valueA.localeCompare(valueB);
      } else if (valueA instanceof Array && valueB instanceof Array) {
        // compare arrays by JSON
        const jsonA = JSON.stringify(valueA);
        const jsonB = JSON.stringify(valueB);
        comparison = jsonA.localeCompare(jsonB);
      } else {
        // default
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        } else {
          comparison = 0;
        }
      }

      return $event.direction === 'asc' ? comparison : -comparison;
    });
  }
}
