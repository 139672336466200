import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { BehaviorSubject, firstValueFrom, Subscription, take } from 'rxjs';
import { FeatureFlagService } from '../modules/shared/services/feature-flag/feature-flag.service';
// import { BehaviorSubject } from 'rxjs';
// import { ElasticSearchQuery } from '../shared/interfaces/elastic-search.interface';

export interface PaginationState {
  rows_per_page: number;
  page_number: number;
}
/*

    State service
        - this is our central location for tracking application state
        - THERE SHOULD BE NO OTHER ACCESS TO STATE VALUES EXCEPT THROUGH THIS SERVICE
        - maybe figure out some cool decorator shorthands

    lets try to tackle advanced search using this state service

    TODO:
        - selection lists need to be generic, we use them in multiple places
            - should support 2 html modes, accordion / none accordion
            - these are shared and persisted until the state is cleared
            - EX: on advance search you can preselect selection lists, once you search
                they should also be checked in the search result selection lists

            - selection lists are dynamic, the options should be coming from the server ideally
                barebone is { text: string, value: string (api_type_code) }
                our inputs should just be the formgroup?
*/
@Injectable({
  providedIn: 'root',
})
export class StateService implements OnDestroy {
  subscriptions: Subscription[] = [];
  // FACETS

  public _state = new UntypedFormGroup({
    searchForm: new UntypedFormGroup({
      keywords: new UntypedFormControl('keyword'),
      query: new UntypedFormControl(''),
    }),

    query: new UntypedFormControl(''),
    queries: new UntypedFormControl([]),
    field_type: new UntypedFormControl(),
    // sorting
    sort_field: new UntypedFormControl(),
    sort_order: new UntypedFormControl('asc'),

    // pagination
    pagination: new UntypedFormGroup({
      rows_per_page: new UntypedFormControl(10),
      page_number: new UntypedFormControl(1),
    }),
    // date filter
    date_picker: new UntypedFormGroup({
      date_type: new UntypedFormControl('representative_date'),
      start_date: new UntypedFormControl(null, { updateOn: 'blur' }),
      end_date: new UntypedFormControl(null, { updateOn: 'blur' }),
    }),
    // facets
    record_type: new UntypedFormGroup({
      allRecords: new UntypedFormControl(true),
      registration: new UntypedFormControl(false),
      recordation: new UntypedFormControl(false),
      acquisition: new UntypedFormControl(false),
    }),

    // highlight
    highlight: new UntypedFormGroup({
      highlight: new UntypedFormControl(true),
    }),

    // table-grid view
    mode: new UntypedFormControl('grid')
  });

  public internalExternalTabs = new BehaviorSubject(this.envService.internal);
  public actionEmitEvents = new BehaviorSubject(false);

  public _registration = new UntypedFormGroup({
    registration_status: new UntypedFormControl([]),
    registration_class: new UntypedFormControl([]),
    registration_item_types: new UntypedFormControl([]),
  });

  recordTypes = [
    { label: 'Registration', value: 'registration' },
    { label: 'Recordation', value: 'recordation' },
    { label: 'Acquisition', value: 'acquisition', visible: this.envService.internal },
  ];

  systemOfOrigin = [
    { label: 'Post-1977 Records', value: 'voyager' },
    { label: 'Card Catalog', value: 'card_catalog' },
    { label: 'Siebel MARC Binary', value: 'siebel', visible: this.envService.internal },
    { label: 'RMM MARC Binary', value: 'rmm_voyager', visible: this.envService.internal, featureFlag: 'rmm_voyager' },
  ];

  registrationStatuses = [
    { label: 'Published', value: 'published' },
    { label: 'Unpublished', value: 'unpublished' },
    { label: 'Unspecified', value: 'unspecified' },
  ];

  registrationClasses = [
    { label: 'Mask Works (MW)', value: 'MW' },
    { label: 'Performing Arts (PA)', value: 'PA' },
    { label: 'Pre-registration (PRE)', value: 'PR' },
    { label: 'Renewals (RE)', value: 'RE' },
    { label: 'Sound Recordings (SR)', value: 'SR' },
    { label: 'Literary (TX)', value: 'TX' },
    { label: 'Visual Arts (VA)', value: 'VA' },
    { label: 'Book', value: 'book' },
    // { label: 'Book or Drama', value: 'book_or_drama' }, new facet, need label

    { label: 'Work of Art', value: 'work_of_art' },
    { label: 'Musical Composition', value: 'musical_composition' },
    { label: 'Map', value: 'map' },
    { label: 'Motion Pictures', value: 'motion_picture' },
    { label: 'Contribution to Newspaper or Periodical', value: 'contribution_to_newspaper_or_periodical' },
    { label: 'Book or Contribution to Newspaper or Periodical', value: 'book_or_contribution_to_newspaper_or_periodical' },
    { label: 'Newspaper or Periodical', value: 'newspaper_or_periodical' },
    { label: 'Dramatic Composition', value: 'dramatic_composition' },
    { label: 'Print or Pictorial Illustration', value: 'print_or_pictorial_illustration' },
    { label: 'Lecture, Sermon or Address', value: 'lecture_sermon_or_address' },
    {
      label: 'Drawing or Plastic Work of Scientific or Technical Character',
      value: 'drawing_or_plastic_work_of_scientific_or_technical_character',
    },
    {
      label: 'Print or Label Used for an Article of Merchandise',
      value: 'print_or_label_used_for_an_article_of_merchandise',
    },
    { label: 'Reproduction of a Work of Art', value: 'reproduction_of_a_work_of_art' },
    { label: 'Photoplay', value: 'photoplay' },
    { label: 'Photograph', value: 'photograph' },

    { label: 'Unspecified', value: 'unspecified' },
    { label: 'Chromo or Lithograph', value: 'chromo_or_lithograph' },
    { label: 'Engraving, Cut or Print', value: 'engraving_cut_or_print' },
    { label: 'Other Item Type', value: 'other_item_type' },
    { label: 'Map or Chart', value: 'map_or_chart' },
    { label: 'Book or Drama', value: 'book_or_drama' },
    { label: 'Music', value: 'music' },
  ];

  registrationItemTypes = [
    { label: 'Computer Files', value: 'computer_file' },
    { label: 'Dramatic Works; or Choreography', value: 'dramatic_work_choreography' },
    { label: 'Kits', value: 'kit' },
    { label: 'Maps', value: 'map' },
    { label: 'Motion Pictures', value: 'motion_picture' },
    { label: 'Music', value: 'music' },
    { label: 'Serials', value: 'serial' },
    { label: 'Text', value: 'text' },
    { label: 'Cancelled Registrations', value: 'cancelled_registration' },
    { label: 'Sound Recording and Text', value: 'sound_recording_text' },
    { label: 'Sound Recording and Music', value: 'sound_recording_music' },
    { label: 'Visual Material', value: 'visual_material' },
    { label: 'Unspecified', value: 'unspecified' },
  ];

  recordationItemTypes = [
    { label: 'Assignment', value: 'assignment' },
    { label: 'Mortgage/Security Agreement', value: 'mortgage_security_agreement' },
    { label: 'DMCA Filing', value: 'dmca_filing' },
    { label: 'Other', value: 'other' },
    { label: 'S.302', value: 's_302' },
    { label: 'S.508', value: 's_508' },
    { label: 'Notice of Termination', value: 'notice_of_termination' },
    { label: 'Declarations about the author', value: 'declarations_about_the_author' },
    { label: 'Court action', value: 'court_action' },
    { label: 'Court actions other than clerk', value: 'court_actions_other_than_clerk' },
    { label: 'Mask works documents', value: 'mask_works_documents' },
    { label: 'Security interests', value: 'security_interests' },
    { label: 'NAFTA', value: 'nafta_statements_of_interest' },
    { label: 'GATT', value: 'gatt_notices_of_intent_to_enforce_a_copyright' },
    { label: 'Unspecified', value: 'unspecified' },
    { label: 'Exclusive License', value: 'exclusive_license' },
    { label: 'Non-exclusive License', value: 'non_exclusive_license' },
    { label: 'Change of Address', value: 'change_of_address' },
    { label: 'Affidavit / Declaration / Certification', value: 'affidavit_declaration_certification' },
    { label: 'Will', value: 'will' },
    { label: 'Change of Name', value: 'change_of_name' },
    { label: 'Counternotice', value: 'counternotice' },
    { label: '203', value: 'notice_of_termination_203' },
    { label: '304(c)', value: 'notice_of_termination_304_c' },
    { label: '304(d)', value: 'notice_of_termination_304_d' },
    { label: 'Court Order', value: 'court_order' },
  ];

  acquisitionItemTypes = [
    { label: 'Voluntary', value: 'voluntary' },
    { label: 'Demand', value: 'demand' },
    { label: 'Unspecified', value: 'unspecified' },
  ];

  acqItemTypes = [
    { label: 'Deposit Only Monographs', value: 'deposit_only_monographs' },
    { label: 'Deposit Only Serials', value: 'deposit_only_serials' },
  ];

  readonly basic_search_options: { text: string; value: string | null; selected?: boolean }[] = [
    { text: 'Keyword', value: 'keyword', selected: true },
    { text: 'Name', value: 'name' },
    { text: 'Title', value: 'title' },
  ];

  public selectionLists: UntypedFormGroup;

  public selectionList: UntypedFormGroup = new UntypedFormGroup({});

  constructor(public router: Router, private formBuilder: UntypedFormBuilder, public envService: EnvService, private featureFlagService: FeatureFlagService) {
    this.selectionLists = this.formBuilder.group({
      registration_status: this.formBuilder.array([]),
      registration_class: this.formBuilder.array([]),
      type_of_work: this.formBuilder.array([]),
      recordation_item_type: this.formBuilder.array([]),
      type_of_record: this.formBuilder.array([]),
      type_of_acquisition: this.formBuilder.array([]),
      acquisition_item_type: this.formBuilder.array([]),
      system_of_origin: this.formBuilder.array([]),
    });

    this.createCheckboxes('type_of_record', this.recordTypes);
    this.createCheckboxes('system_of_origin', this.systemOfOrigin);
    this.createCheckboxes('registration_status', this.registrationStatuses);
    this.createCheckboxes('registration_class', this.registrationClasses);
    this.createCheckboxes('type_of_work', this.registrationItemTypes);
    this.createCheckboxes('recordation_item_type', this.recordationItemTypes);
    this.createCheckboxes('type_of_acquisition', this.acquisitionItemTypes);
    this.createCheckboxes('acquisition_item_type', this.acqItemTypes);
  }

  // get pagination -> page_number get(['pagination', 'rows_per_page'])
  getControls(parent: string, children?: string[]): Object {
    const returnObj = {};

    if (children) {
      const parentGroup = this._state.get(parent);
      if (parentGroup) {
        children.forEach((c) => (returnObj[c] = parentGroup.get(c)?.value));
      }
    } else {
      returnObj[parent] = this._state.get(parent);
    }

    return returnObj;
  }

  setQueries(queries: UntypedFormGroup[]) {
    this._state.get('queries')?.setValue(queries);

    return this.getQueries();
  }

  removeLastQuery(index: number = -1) {
    const queries = this.getQueries();
    queries.splice(index, 1);

    return this.setQueries(queries);
  }

  addQuery(query: UntypedFormGroup) {
    const queries = this.getQueries();
    queries.push(query);

    return this.setQueries(queries);
  }

  getQueries(): UntypedFormGroup[] {
    return this._state.get('queries')?.value;
  }

  setPagination(paginationState: PaginationState): PaginationState {
    this._state.get('pagination')?.get('rows_per_page')?.setValue(paginationState.rows_per_page);
    this._state.get('pagination')?.get('page_number')?.setValue(paginationState.page_number);

    return this.getPagination();
  }

  getPagination(): PaginationState {
    return {
      rows_per_page: this._state.get('pagination')?.get('rows_per_page')?.value,
      page_number: this._state.get('pagination')?.get('page_number')?.value,
    };
  }

  getSelection(selectionList: string) {
    const options: { label: string; value: string; selected: boolean }[] =
      this.selectionLists.get(selectionList)?.value;

    return options.filter((checkbox) => checkbox.selected).map((c) => c.value);
  }

  getSelectionWithLabels(selectionList: string) {
    const options: { label: string; value: string; selected: boolean }[] =
      this.selectionLists.get(selectionList)?.value;

    return options.filter((checkbox) => checkbox.selected);
  }

  clearSelectionLists() {
    this.type_of_record.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.system_of_origin.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.registration_status.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.type_of_work.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.registration_class.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.recordation_item_type.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.type_of_acquisition.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    this.acquisition_item_type.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
  }

  addCheckbox(selectionList: string, checkbox: { label: string; value: string; selected: boolean } | null) {
    if (!checkbox) {
      return;
    }
    const control = this.formBuilder.group({
      label: checkbox.label,
      value: checkbox.value,
      histogram: 0,
      selected: checkbox.selected,
    });
    (this.selectionLists.get(selectionList) as UntypedFormArray).push(control);

    return control;
  }

  createCheckboxes(selectionList: string, data: { label: string; value: string }[]) {
    return data
      .map(async (status) => {
        const hasVisibleProperty = 'visible' in status;
        const hasFeatureFlagProperty = 'featureFlag' in status;
        if (hasVisibleProperty && !status['visible']) {
          return null;
        }
        if (hasFeatureFlagProperty && status['featureFlag'] === 'rmm_voyager') {
          const enabled = await firstValueFrom(this.featureFlagService.isRMMVoyagerEnabled().pipe(take(1)));
          if (!enabled) {
            return null;
          }
        }
        return {
          label: status.label,
          value: status.value,
          histogram: 0,
          selected: false,
        };
      })
      .map(async (checkbox) => this.addCheckbox(selectionList, await checkbox));
  }

  get date_type_options() {
    return [
      { text: 'Dates', value: 'representative_date' },
      { text: 'Effective Date of Registration', value: 'registration_date_as_date' },
      // first_published_date_as_date is card catalog. We filter by this.
      // date_of_first_publication_as_date is voyager. We don't filter by it. It just shows on DRV
      { text: 'Date of Publication', value: 'first_published_date_as_date' },
      { text: 'Date of Creation', value: 'date_creation_date_as_year' },
      { text: 'Date of Certification', value: 'date_certification_date_statement_as_date' },
      { text: 'Date of Recordation', value: 'recordation_date_as_date' },
      // { text: 'Date of Publication (Acquisitions)', value: 'imprint_date_of_publication_as_year' },
      // { text: 'execution_date_statement_as_date', value: 'execution_date_statement_as_date' },
      // { text: 'imprint_date_of_publication_as_year', value: 'imprint_date_of_publication_as_year' },
      { text: 'Source Date', value: 'source_date', internal: true },
    ].filter(option => !(!this.envService.internal && option.internal));
  }

  get sort_field_options() {
    return [
      { text: 'Relevance', value: { sort_field: 'relevancy', sort_order: 'asc' } },
      { text: 'Title A-Z', value: { sort_field: 'full_title', sort_order: 'asc' } },
      { text: 'Title Z-A', value: { sort_field: 'full_title', sort_order: 'desc' } },
      { text: 'Date (Oldest)', value: { sort_field: 'representative_date', sort_order: 'asc' } },
      { text: 'Date (Newest)', value: { sort_field: 'representative_date', sort_order: 'desc' } },
      { text: 'Source Date (Oldest)', value: { sort_field: 'source_date', sort_order: 'asc' }, internal: true },
      { text: 'Source Date (Newest)', value: { sort_field: 'source_date', sort_order: 'desc' }, internal: true },
    ].filter(option => !(!this.envService.internal && option.internal));
  }

  getFormGroup(key: string): UntypedFormGroup {
    return this._state.get(key) ? (this._state.get(key) as UntypedFormGroup) : new UntypedFormGroup({});
  }

  get search_form(): UntypedFormGroup {
    return this._state.get('searchForm') as UntypedFormGroup;
  }

  get type_of_record(): UntypedFormArray {
    return this.selectionLists.get('type_of_record') as UntypedFormArray;
  }

  get system_of_origin(): UntypedFormArray {
    return this.selectionLists.get('system_of_origin') as UntypedFormArray;
  }

  get registration_status(): UntypedFormArray {
    return this.selectionLists.get('registration_status') as UntypedFormArray;
  }

  get registration_class(): UntypedFormArray {
    return this.selectionLists.get('registration_class') as UntypedFormArray;
  }

  get type_of_work(): UntypedFormArray {
    this.selectionLists.get('type_of_work')?.value.forEach((element: any) => {
      if (element.label === 'Cancelled Registrations') {
        element.label = 'Cancelled Registration';
        const typeOfWork = this.selectionLists.get('type_of_work')?.value;
        this.selectionLists.get('type_of_work')?.patchValue(typeOfWork);
      }
    });
    return this.selectionLists.get('type_of_work') as UntypedFormArray;
  }

  get recordation_item_type(): UntypedFormArray {
    return this.selectionLists.get('recordation_item_type') as UntypedFormArray;
  }

  get type_of_acquisition(): UntypedFormArray {
    return this.selectionLists.get('type_of_acquisition') as UntypedFormArray;
  }

  get acquisition_item_type(): UntypedFormArray {
    return this.selectionLists.get('acquisition_item_type') as UntypedFormArray;
  }

  get date_picker(): UntypedFormGroup {
    return this._state.get('date_picker') as UntypedFormGroup;
  }

  get pagination(): UntypedFormGroup {
    return this._state.get('pagination') as UntypedFormGroup;
  }

  getMegaMiniMenuOption(value: string): { text: string; value: string } {
    const options = this.mega_mini_menu_options;
    const groups = Object.keys(this.mega_mini_menu_options);
    const found = groups
      .map((key) => {
        const group: { text: string; value: string }[] = options[key];
        return group.find((option) => option.value === value);
      })
      .filter(Boolean);

    if (found.length > 0) {
      const value = found.pop();
      if (value) {
        return value;
      }
    }
    throw new Error('Cannot find corresponding mmm option');
  }

  get mega_mini_menu_array(): { text: string; value: string }[] {
    let array: any[] = [];

    array = array.concat(
      this.mega_mini_menu_options.general,
      this.mega_mini_menu_options.copyright_numbers,
      this.mega_mini_menu_options.names,
      this.mega_mini_menu_options.other,
      this.mega_mini_menu_options.standardized_numbers
    );

    return array;
  }

  get mega_mini_menu_options(): {
    general: { text: string; value: string }[];
    copyright_numbers: { text: string; value: string }[];
    names: { text: string; value: string }[];
    standardized_numbers: { text: string; value: string }[];
    other: { text: string; value: string }[];
  } {
    return {
      general: [
        { text: 'Keyword', value: 'keyword' },
        { text: 'Title', value: 'titles' },
        { text: 'Notes', value: 'notes' },
        { text: 'Series', value: 'series' },
      ],
      copyright_numbers: [
        { text: 'All Copyright Numbers', value: 'all_copyright_numbers' },
        { text: 'Recordation Number', value: 'recordation_numbers' },
        { text: 'Registration Number', value: 'registration_numbers' },
        { text: 'SR Number', value: 'sr_numbers' },
      ],
      names: [
        { text: 'All Names', value: 'all_names' },
        { text: 'Author', value: 'authors' },
        { text: 'Claimants', value: 'claimants' },
        { text: 'Party 1', value: 'party_1' },
        { text: 'Party 2', value: 'party_2' },
        { text: 'Publisher', value: 'publishers' },
      ],
      standardized_numbers: [
        { text: 'All Standardized Numbers', value: 'all_standardized_numbers' },
        { text: 'ISBN', value: 'isbn' },
        { text: 'ISSN', value: 'issn' },
        { text: 'ISRC', value: 'isrc' },
      ],
      other: [
        { text: 'Publication Location', value: 'publication_location' },
        { text: 'Publisher Number', value: 'publisher_number' },
        { text: 'Physical Description', value: 'physical_description' },
        { text: 'Material Excluded', value: 'pre_existing_material' },
        { text: 'Deposit Box Number', value: 'deposit_box_number' },
        { text: 'Deposit Dispatch/Custody', value: 'deposit_dispatch_custody' },
        { text: 'Deposit Tracking Number (IBAL)', value: 'deposit_tracking_number' },
      ],
    };
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
