import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-record-editor-page',
  templateUrl: './record-editor-page.component.html',
  styleUrls: ['./record-editor-page.component.scss'],
})
export class RecordEditorPageComponent implements OnInit {
  noResults: boolean = false;
  public search2Form = new UntypedFormGroup({
    query: new UntypedFormControl(''),
    keywords: new UntypedFormControl(''),
  });

  readonly recordEditorSearchOptions: { text: string; value: string | null; selected?: boolean }[] = [
    { text: 'Copyright Number', value: 'copyright_number', selected: true },
    { text: 'Bib ID', value: 'bib_id' },
  ];

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    if (!this.search2Form.get('keywords')?.value) {
      this.search2Form.get('keywords')?.setValue('copyright_number');
    }

    this.breadcrumbService.breadcrumbs = [
      {
        name: 'Home',
        link: '/',
        linkType: 'routerLink',
      },
    ];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Record Editor';
  }

  search() {
    this.noResults = false;
    console.info('Process search when enabled');
    this.noResults = true;
  }
}
