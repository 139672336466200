<div id="cprs-advanced-search" class="container p-4">
  <div class="row">
    <cd-breadcrumb
      [trail]="this.breadcrumbService.breadcrumbs"
      [current]="this.breadcrumbService.currentBreadcrumbTextOnly"
    ></cd-breadcrumb>
  </div>

  <div class="row">
    <h1 class="display-3 mb-3">Advanced Search</h1>
    <ng-container>
      <div class="display-6 mb-3">Select record type to begin advanced search</div>

      <div class="d-flex">
        <cprs-record-type-selection
          (recordTypeSelected)="recordTypeSelected()"
          class="d-block mb-4"
        ></cprs-record-type-selection>
      </div>
    </ng-container>

    <ng-container *ngFor="let form of rows; let first = first; let last = last; let i = index">
      <div class="row d-flex">
          <cprs-advanced-search-row
            class="mb-3"
            [formGroup]="form"
            [showOperator]="!first"
            [showButtons]="last"
            [showShare]="first && hasSearched"
            (search)="search()"
          >
        </cprs-advanced-search-row>
      </div>
  
      <!-- Row Controls-->
      <div>
        <cd-button *ngIf="!first" class="pe-2" size="small" mode="text" (btnClick)="removeRow(i)">
          <cd-icon class="pe-2" [cdsIcon]="'minus'"></cd-icon>Remove row
        </cd-button>
        <cd-button *ngIf="last" class="pe-2" size="small" mode="text" (btnClick)="addRow($event)" [disabled]="this.rows.length >= 4">
          <cd-icon class="pe-2" [cdsIcon]="'plus'"></cd-icon>Add a row
        </cd-button>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-sm-4 col-md-2 px-3 mt-2 pb-3" style="width: fit-content">
        <cd-button-group>
          <cd-button [mode]="'secondary'" class="pe-3" (btnClick)="clear()">Clear all</cd-button>
          <cd-button [mode]="'primary'" (btnClick)="search()">Search</cd-button>
        </cd-button-group>
      </div>
    </div>
    <!-- Additional -->
  </div>
  <div>
    <ng-container *ngIf="current_search && hasSearched && isLoggedIn">
      <div class="d-flex align-items-center justify-content-end py-2 mb-3 border-bottom">
        <div *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
          <ng-container *ngIf="isSaved() && !isSaving">
            <cd-button mode="text" size="small" (btnClick)="removeSearch()">
              <cd-icon [cdsIcon]="'save'"></cd-icon> Remove Search
            </cd-button>
          </ng-container>
          <ng-container *ngIf="!isSaved() && !isSaving">
            <cd-button mode="text" size="small" (btnClick)="saveSearch()">
              <cd-icon [cdsIcon]="'save'"></cd-icon> Save Search
            </cd-button>
          </ng-container>
          <ng-container *ngIf="isSaving">
            <cd-button mode="text" size="small">
              <cd-icon [cdsIcon]="'spinner'" [classes]="'fa-spin'"></cd-icon> {{ savingLabel }}
            </cd-button>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!hasSearched" class="cprs-additional-advanced-search">
      <h2 class="display-4">Additional search tools/filters</h2>

      <form [formGroup]="this.stateService.selectionLists">
        <div class="container row">
          <div class="filter-column col px-3 py-3 me-4 mb-4">
            <h3 class="display-5 mb-3">Registration Filters</h3>
            <cprs-module-selection-list
              title="Registration Status"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('registration_status')"
            >
            </cprs-module-selection-list>

            <cprs-module-selection-list
              title="Registration Class"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('registration_class')"
            >
            </cprs-module-selection-list>

            <cprs-module-selection-list
              title="Registration Item Type"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('type_of_work')"
            >
            </cprs-module-selection-list>
          </div>

          <!-- Recordation -->
          <div class="filter-column col px-3 py-3 me-4 mb-4">
            <h3 class="display-5 mb-3">Recordation Filters</h3>
            <cprs-module-selection-list
              title="Recordation Document Type"
              class="d-block mb-3"
              mode="default"
              [histogram]="false"
              [formGroup]="this.stateService.selectionLists"
              [formArray]="this.getFormArray('recordation_item_type')"
            >
            </cprs-module-selection-list>
          </div>

          <!-- Acquisition -->
          <ng-container *ngIf="this.envService.internal">
            <div class="filter-column col px-3 py-3 me-4 mb-4">
              <h3 class="display-5 mb-3">Acquisition Filters</h3>

              <cprs-module-selection-list
                title="Type of Acquisition"
                class="d-block mb-3"
                mode="default"
                [histogram]="false"
                [formGroup]="this.stateService.selectionLists"
                [formArray]="this.getFormArray('type_of_acquisition')"
              >
              </cprs-module-selection-list>

              <cprs-module-selection-list
                title="Acquisition Item Type"
                class="d-block mb-3"
                mode="default"
                [histogram]="false"
                [formGroup]="this.stateService.selectionLists"
                [formArray]="this.getFormArray('acquisition_item_type')"
              >
              </cprs-module-selection-list>
            </div>
          </ng-container>
          <!-- Collection -->
          <ng-container>
            <div class="filter-column col-md-12 px-3 py-3 me-4 mb-4">
              <h3 class="display-5 mb-3">Collection Filters</h3>

              <cprs-module-selection-list
                title="Collections Type"
                class="d-block mb-3"
                mode="default"
                [histogram]="false"
                [formGroup]="this.stateService.selectionLists"
                [formArray]="this.getFormArray('system_of_origin')"
              >
              </cprs-module-selection-list>
            </div>
          </ng-container>

          <div class="filter-column col-md-12 px-3 py-3 me-1 mb-4">
            <h3 class="display-5 mb-3">Filter by date</h3>

            <cprs-module-date-picker [showControls]="false"></cprs-module-date-picker>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngIf="this.hasSearched">
    <h1 class="display-4">Search Results</h1>
  </ng-container>

  <div id="cprs-search-results-on-advanced-search" *ngIf="hasSearched">
    <cprs-module-search-results></cprs-module-search-results>
  </div>
</div>

<app-back-to-top></app-back-to-top>
