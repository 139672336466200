import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '@src/app/cprs/state/state.service';
import { CdSelectionService } from '../../services/selection.service';
import { Page } from '@cop/design/services';

@Component({
  selector: '[cprsSelectAll]',
  template: `
    <form [formGroup]="formGroup">
      <cd-input-crs [inputClasses]="'fix-spacing'" [labelHidden]="true" [label]="'Select All'" formControlName="selectAll" (click)="onClick()"></cd-input-crs>
    </form>
  `,
})
export class SelectAllComponent implements OnInit {
  @Input() cprsSelectAll: string;
  @Input() data: unknown[] = [];

  public formGroup: UntypedFormGroup;

  public override = false;

  constructor(
    public stateService: StateService,
    public cdSelectionService: CdSelectionService
  ) { }

  ngOnInit(): void {
    const group = this.cdSelectionService.getSelectionGroup(this.cprsSelectAll);
    this.formGroup = group;
  }

  onClick() {
    const numSelectedOnPage = this.cdSelectionService.numSelectedOnPage(this.cprsSelectAll);
    const totalSelected = this.cdSelectionService.totalSelected(this.cprsSelectAll);
    // TODO: this needs to be isolated out of this component, and needs to come from a service
    // its better that way...
    let page_number = this.stateService.pagination.get('page_number')?.value;
    let rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;

    const pageControl: Page<any> = this.formGroup.get('page')?.value;
    if (pageControl) {
      page_number = pageControl.number + 1;
      rows_per_page = pageControl.size;
    }

    if (totalSelected === this.formGroup.get('limit')?.value) {
      this.cdSelectionService.deselectAllByKey(this.cprsSelectAll);
      return;
    }

    if (numSelectedOnPage === 0) {
      this.cdSelectionService.selectPageByKey(this.cprsSelectAll);
      this.formGroup.get('selectAll')?.setValue(true);
      return;
    }

    // okay so we need to get the current set of items
    const startIndex = (page_number - 1) * rows_per_page + 1;
    const endIndex = page_number * rows_per_page;
    const splicedSet: UntypedFormGroup[] = this.formGroup.get('items')?.value;
    const byPage = splicedSet.filter((group) => {
      const index = group.get('index')?.value;
      return startIndex <= index && index <= endIndex;
    });
    if (
      numSelectedOnPage === rows_per_page ||
      numSelectedOnPage === byPage.length ||
      this.formGroup.get('limit')?.value === this.cdSelectionService.totalSelected(this.cprsSelectAll)
    ) {
      this.cdSelectionService.deselectAllByKey(this.cprsSelectAll);
      return;
    }

    if (numSelectedOnPage > 0 && numSelectedOnPage < rows_per_page) {
      this.cdSelectionService.selectPageByKey(this.cprsSelectAll);
      this.formGroup.get('selectAll')?.setValue(true);
    }
  }
}
