import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'cprs-advanced-search-row',
  templateUrl: './advanced-search-row.component.html',
  styleUrls: ['./advanced-search-row.component.scss'],
})
export class AdvancedSearchRowComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});

  @Input() showButtons = true;

  @Input() showOperator = false;

  @Input() showShare = false;

  @Output() search = new EventEmitter();

  // @Output() clear = new EventEmitter();

  // form
  public advanced_search = new UntypedFormGroup({
    operator_type: new UntypedFormControl('AND'),
    column_name: new UntypedFormControl('keyword'),
    type_of_query: new UntypedFormControl('phrase'),
    query: new UntypedFormControl(''),
  });

  // options
  // public field_heading_options = [];

  public mmmSelection = { text: 'Keyword', value: 'keyword' };

  public search_type_options: any[] = [
    { text: 'As a Phrase', value: 'phrase' },
    { text: 'Contains', value: 'contains' },
    { text: 'Starts with', value: 'starts_with' },
    { text: 'Is Exact', value: 'exact' },
  ];

  public operator_options: any[] = [
    { text: 'AND', value: 'AND' },
    { text: 'OR', value: 'OR' },
    { text: 'NOT', value: 'NOT' },
  ];

  constructor(
    public stateService: StateService,
    public featureFlag: FeatureFlagService
  ) { }

  ngOnInit(): void {
    const columnName = this.formGroup.get('column_name');
    if (!columnName) {
      throw new Error('column_name control not found, cannot continue');
    }
    const mmm = columnName.value;
    this.featureFlag.isContainsSearchEnabled().subscribe(enabled => {
      if (!enabled) {
        this.search_type_options = this.search_type_options.filter(o => o.value !== 'contains');
      }
    })
    this.stateService.actionEmitEvents.subscribe((event) => {
      if (event) {
        this.onSearch(true);
      }
    })

    this.mmmSelection = this.stateService.getMegaMiniMenuOption(mmm);
  }

  onSearch(isSubmit: any) {
    if (!isSubmit) {
      return;
    }
    const query = this.formGroup.get('query')
    if (this.formGroup && query && query?.value === '') {
      query.setErrors({ required: true });
      query.markAsDirty();
    } else {
      this.search.emit();
    }
  }

  // onClear() {
  //   this.clear.emit();
  // }

  mmm(event: { text: string; value: string }) {
    this.formGroup.get('column_name')?.setValue(event.value);
  }
}
