import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTemplateComponent } from './shared/page-template/page-template.component';
import { DesignComponentsModule } from '@cop/design/components';
import { DesignLayoutModule } from '@cop/design/layout';
import { DesignTableModule } from '@cop/design/table';
import { RouterModule } from '@angular/router';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component';
import { RecordTypeSelectionComponent } from './shared/record-type-selection/record-type-selection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignFormsModule } from '@cop/design/forms';
import { AdvancedSearchRowComponent } from './shared/advanced-search-row/advanced-search-row.component';
import { MegaMiniMenuComponent } from './shared/mega-mini-menu/mega-mini-menu.component';
import { SimpleSearchComponent } from './pages/simple-search/simple-search.component';
import { SharedModule } from '../shared/shared.module';
import { BasePageComponent } from './pages/base-page/base-page.component';
import { CprsSearchComponent } from './shared/cprs-search/cprs-search.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SearchResultsModule } from './modules/search-results/search-results.module';
import { DesignShareModule } from '@cop/design/_share';
import { RecentRecordsPageComponent } from './pages/recent/recent-records-page/recent-records-page.component';
import { RecentSearchesPageComponent } from './pages/recent/recent-searches-page/recent-searches-page.component';
import { RecentModule } from './modules/recent/recent.module';
import { StorageService } from './services/storage.service';
import { DetailedRecordPageComponent } from './pages/detailed-record-page/detailed-record-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { SavedModule } from './modules/saved/saved.module';
import { ManageSaveComponent } from './pages/saved/manage-save.component';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';
import { TableModule as CprsTableModule } from './modules/table/table.module';
import { NameDirectoryPageComponent } from './pages/name-directory-page/name-directory-page.component';
import { AssociatedRecordsPageComponent } from './pages/associated-records-page/associated-records-page.component';
import { DetailedRecordModule } from './modules/detailed-record/detailed-record.module';
import { SharedModule as CprsSharedModule } from './modules/shared/shared.module';
import { SelectionModule } from './modules/selection/selection.module';
import { DialogsModule } from './modules/dialogs/dialogs.module';
import { RecordEditorPageComponent } from './pages/record-editor-page/record-editor-page.component';
@NgModule({
  declarations: [
    PageTemplateComponent,
    AdvancedSearchComponent,
    RecordTypeSelectionComponent,
    AdvancedSearchRowComponent,
    MegaMiniMenuComponent,
    SimpleSearchComponent,
    BasePageComponent,
    CprsSearchComponent,
    HomePageComponent,
    RecentRecordsPageComponent,
    RecentSearchesPageComponent,
    DetailedRecordPageComponent,
    HelpPageComponent,
    ManageSaveComponent,
    UnsubscribePageComponent,
    NameDirectoryPageComponent,
    AssociatedRecordsPageComponent,
    RecordEditorPageComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    DesignComponentsModule,
    DesignLayoutModule,
    DesignTableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DesignShareModule,
    DesignFormsModule,
    SharedModule,
    SearchResultsModule,
    RecentModule,
    SavedModule,
    CprsTableModule,
    DetailedRecordModule,
    CprsSharedModule,
    SelectionModule,
    DialogsModule
  ],
  exports: [
    BasePageComponent,
    PageTemplateComponent,
    AdvancedSearchComponent,
    RecordTypeSelectionComponent,
    AdvancedSearchRowComponent,
    MegaMiniMenuComponent,
    SimpleSearchComponent,
    HomePageComponent,
    ManageSaveComponent,
  ],
  providers: [StorageService],
})
export class CprsModule {}
