<cd-modal [(opened)]="downloadModal">
  <div title>Select file type</div>
  <div body>
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <cd-button mode="text" (btnClick)="selectOption('pdf')" [ariaLabel]="'PDF'">
            <cd-icon
              [classes]="'p-1 fs-1'"
              [class.text-secondary]="!isSelected('pdf')"
              [class.text-primary]="isSelected('pdf')"
              [cdsIcon]="'file-pdf'"
            ></cd-icon>
            <span [class.text-secondary]="!isSelected('pdf')" [class.text-primary]="isSelected('pdf')">PDF</span>
          </cd-button>
        </div>
        <div class="col-sm-12 col-md-4">
          <cd-button mode="text" (btnClick)="selectOption('txt')" [ariaLabel]="'TXT'">
            <cd-icon
              [classes]="'p-1 fs-1'"
              [class.text-secondary]="!isSelected('txt')"
              [class.text-primary]="isSelected('txt')"
              [cdsIcon]="'file-alt'"
            ></cd-icon>
            <span [class.text-secondary]="!isSelected('txt')" [class.text-primary]="isSelected('txt')">Text</span>
          </cd-button>
        </div>
        <!-- <div *ngIf="this.isRecentSearches()" class="col-sm-12 col-md-4">
          <cd-button mode="text" (btnClick)="selectOption('xml')" [ariaLabel]="'XML'">
            <cd-icon
              [classes]="'p-1 fs-1'"
              [class.text-secondary]="!isSelected('xml')"
              [class.text-primary]="isSelected('xml')"
              [cdsIcon]="'file-excel'"
            ></cd-icon>
            <span [class.text-secondary]="!isSelected('xml')" [class.text-primary]="isSelected('xml')">XML</span>
          </cd-button>
        </div> -->
        <div *ngIf="!this.isRecentSearches() && !this.isSavedSearches()" class="col-sm-12 col-md-4">
          <cd-button mode="text" (btnClick)="selectOption('csv')" [ariaLabel]="'CSV'">
            <cd-icon
              [classes]="'p-1 fs-1'"
              [class.text-secondary]="!isSelected('csv')"
              [class.text-primary]="isSelected('csv')"
              [cdsIcon]="'file-excel'"
            ></cd-icon>
            <span [class.text-secondary]="!isSelected('csv')" [class.text-primary]="isSelected('csv')">CSV</span>
          </cd-button>
        </div>

        <div *ngIf="!this.formGroup.valid && this.error_message" class="col-12 mt-2 justify-content-center">
          <span style="font-size: 0.75rem" class="text-danger">{{ this.error_message | titlecase }}</span>
        </div>
      </div>
    </div>
  </div>
  <div footer>
    <cd-button [mode]="'secondary'" class="pe-3" (btnClick)="close()">Cancel</cd-button>
    <cd-button [mode]="'primary'" (btnClick)="download()">Download</cd-button>
  </div>
</cd-modal>

<cd-modal alert="success" [(opened)]="successModal">
  <div title>Success!</div>
  <div body>Your email has been successfully sent.</div>
  <div footer>
    <cd-button [mode]="'primary'" (btnClick)="close()">Close</cd-button>
  </div>
</cd-modal>
