<cd-table [caption]="interface.caption" [columns]="visibleColumns">
  <thead>
    <tr>
      <th
        *ngIf="showSelectControls && content.length > 0"
        style="width: 36px"
        scope="col"
        [cprsSelectAll]="selectionKey"
      ></th>
      <!-- <th cdExpandAll></th> -->
      <th *ngIf="showIndexColumn" scope="col" cdResizable>#</th>
      <ng-container *ngFor="let column of this.modeColumns">
        <!-- a bunch of states here, sortable, resizable etc... -->
        <ng-container *ngIf="!column.sortable">
          <!-- <th class="display-7 fw-bold" [cdDisplayColumn]="column.columnKey">{{ column.label }}</th> -->
          <th [cdDisplayColumn]="column.columnKey">{{ column.label }}</th>
        </ng-container>

        <ng-container *ngIf="column.sortable">
          <th
            [cdDisplayColumn]="column.columnKey"
            [cdSort]="column.columnKey"
            [cdSortDefault]="getSortDefault(column)"
            [cdSortResizable]="isResizable(column)"
            (sort)="sortTable($event)"
          >
            {{ column.label }}
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="pagingType === 'client'">
      <tr
        *ngFor="
          let data of content | paginate : { itemsPerPage: fakePage.size, currentPage: fakePage.number + 1 };
          let index = index
        "
      >
        <td *ngIf="showSelectControls" [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
        <td *ngIf="showIndexColumn">{{ this.getIndex(index) | number }}</td>
        <!-- <td cdExpand></td> -->
        <ng-container *ngFor="let column of this.modeColumns">
          <td [cdDisplayColumn]="column.columnKey">
            <ng-container *ngIf="data[column.columnKey] && data[column.columnKey].url">
              <a
                [href]="data[column.columnKey].url"
                (click)="$event.preventDefault(); navigate(data[column.columnKey].url, data[column.columnKey]?.params)"
                >
                <ng-container *ngIf="column.pipe !== 'number'">
                  {{ data[column.columnKey].value }}
                </ng-container>
                <ng-container *ngIf="column.pipe === 'number'">
                  {{ data[column.columnKey].value | number }}
                </ng-container>
                </a>
            </ng-container>
            <ng-container *ngIf="data[column.columnKey] && !data[column.columnKey].url">
              <ng-container *ngIf="column.pipe !== 'number'">
                {{ data[column.columnKey].value }}
              </ng-container>
              <ng-container *ngIf="column.pipe === 'number'">
                {{ data[column.columnKey].value | number }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-container>
    <ng-container *ngIf="pagingType === 'server'">
      <tr *ngFor="let data of content; let index = index">
        <td *ngIf="showSelectControls" [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
        <td *ngIf="showIndexColumn">{{ this.getIndex(index) | number }}</td>
        <!-- <td cdExpand></td> -->
        <ng-container *ngFor="let column of this.modeColumns">
          <td [cdDisplayColumn]="column.columnKey">
            <ng-container *ngIf="data[column.columnKey] && data[column.columnKey].url">
              <a
                [href]="data[column.columnKey].url"
                (click)="$event.preventDefault(); navigate(data[column.columnKey].url, data[column.columnKey]?.params)"
                >
                <ng-container *ngIf="column.pipe !== 'number'">
                  {{ data[column.columnKey].value }}
                </ng-container>
                <ng-container *ngIf="column.pipe === 'number'">
                  {{ data[column.columnKey].value | number }}
                </ng-container>
                </a>
            </ng-container>
            <ng-container *ngIf="data[column.columnKey] && !data[column.columnKey].url">
              <ng-container *ngIf="column.pipe !== 'number'">
                {{ data[column.columnKey].value }}
              </ng-container>
              <ng-container *ngIf="column.pipe === 'number'">
                {{ data[column.columnKey].value | number }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-container>

    <ng-container *ngIf="content.length === 0">
      <td class="container" colspan="12">
        <ng-content select="[no-content-table]"></ng-content>
      </td>
    </ng-container>
  </tbody>
</cd-table>
