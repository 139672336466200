<cd-table [caption]="interface.caption" [columns]="visibleColumns">
  <tbody>
    <ng-container *ngIf="pagingType === 'client'">
      <ng-container
        *ngFor="
          let data of content | paginate : { itemsPerPage: fakePage.size, currentPage: fakePage.number + 1 };
          let index = index;
          let odd = odd
        "
      >
        <div class="search-result-pane d-flex" [ngClass]="{ gray: odd }">
          <div *ngIf="showSelectControls" class="col-1" style="flex: 0">
            <td [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
          </div>
          <div *ngIf="showIndexColumn" class="col-1" style="flex: 0">
            <strong>{{ this.getIndex(index) | number }}.</strong>
          </div>
          <div class="col-8">
            <ul class="row">
              <ng-container *ngFor="let columnKey of this.visibleColumns; let first = first; let last = last">
                <!-- Omit anything without a value -->
                <ng-container *ngIf="data[columnKey] && data[columnKey].value">
                  <ng-container
                    *ngIf="
                      !data[columnKey].hasOwnProperty('visible') ||
                      (data[columnKey].hasOwnProperty('visible') && data[columnKey].visible)
                    "
                  >
                    <li [class.col-md-12]="first" [class.col-md-6]="!first" [class.col-sm-12]="true">
                      <div class="control d-flex pb-1">
                        <div class="control-label pe-2">
                          <strong *ngIf="data[columnKey] && data[columnKey].label">{{ data[columnKey].label }}:</strong>
                          <strong *ngIf="!data[columnKey] || !data[columnKey].label"
                            >{{ this.getColumnLabel(columnKey) }}:</strong
                          >
                        </div>

                        <div
                          class="control-value truncate"
                          [class.primary]="first"
                          [attr.tabindex]="first ? 0 : -1"
                          style="max-width: 750px; padding: 0 0.3%"
                          (keydown)="onKeyDown($event)"
                        >
                          <ng-container *ngIf="data[columnKey] && data[columnKey].url">
                            <a
                              class="link"
                              [attr.tabindex]="first ? -1 : 0"
                              [href]="data[columnKey].url"
                              [routerLink]="data[columnKey].url"
                              >{{ data[columnKey].value }}</a
                            >
                          </ng-container>
                          <ng-container *ngIf="data[columnKey] && !data[columnKey].url">
                            {{ data[columnKey].value }}
                          </ng-container>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>

            <div *ngIf="headerTemplateRef" class="search-result-pane-footer mt-2 pe-2">
              <!-- fancy content projection, allows access to the iterative data items -->
              <ng-container
                *ngIf="headerTemplateRef"
                [ngTemplateOutlet]="headerTemplateRef"
                [ngTemplateOutletContext]="{ $implicit: data }"
              >
              </ng-container>
            </div>
          </div>

          <div *ngIf="sideColumnRef" class="side-column">
            <!-- fancy content projection, allows access to the iterative data items -->
            <ng-container
              *ngIf="sideColumnRef"
              [ngTemplateOutlet]="sideColumnRef"
              [ngTemplateOutletContext]="{ $implicit: data }"
            >
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="pagingType === 'server'">
      <ng-container *ngFor="let data of content; let index = index; let odd = odd">
        <div class="search-result-pane d-flex" [ngClass]="{ gray: odd }">
          <div *ngIf="showSelectControls" class="col-1" style="flex: 0">
            <td [cprsChildSelect]="selectionKey" [data]="data" [index]="index"></td>
          </div>
          <div *ngIf="showIndexColumn" class="col-1" style="flex: 0">
            <strong>{{ this.getIndex(index) | number }}.</strong>
          </div>
          <div class="col-10">
            <ul class="row">
              <ng-container *ngFor="let columnKey of this.visibleColumns; let first = first; let last = last">
                <!-- Omit anything without a value -->
                <ng-container *ngIf="data[columnKey] && data[columnKey].value">
                  <ng-container
                    *ngIf="
                      !data[columnKey].hasOwnProperty('visible') ||
                      (data[columnKey].hasOwnProperty('visible') && data[columnKey].visible)
                    "
                  >
                    <li [class.col-md-12]="first" [class.col-md-6]="!first" [class.col-sm-12]="true">
                      <div class="control d-flex">
                        <div class="control-label pe-2">
                          <strong *ngIf="data[columnKey] && data[columnKey].label">{{ data[columnKey].label }}:</strong>
                          <strong *ngIf="!data[columnKey] || !data[columnKey].label"
                            >{{ this.getColumnLabel(columnKey) }}:</strong
                          >
                        </div>

                        <div
                          class="control-value truncate"
                          [class.primary]="first"
                          [attr.tabindex]="first ? 0 : -1"
                          style="max-width: 750px; padding: 0 0.3%"
                          (keydown)="onKeyDown($event)"
                        >
                          <ng-container *ngIf="data[columnKey] && data[columnKey].url">
                            <a
                              class="link"
                              [attr.tabindex]="first ? -1 : 0"
                              [href]="data[columnKey].url"
                              [routerLink]="data[columnKey].url"
                              >{{ data[columnKey].value }}</a
                            >
                          </ng-container>
                          <ng-container *ngIf="data[columnKey] && !data[columnKey].url">
                            {{ data[columnKey].value }}
                          </ng-container>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>

            <div *ngIf="headerTemplateRef" class="search-result-pane-footer mt-2 pe-2">
              <!-- fancy content projection, allows access to the iterative data items -->
              <ng-container
                *ngIf="headerTemplateRef"
                [ngTemplateOutlet]="headerTemplateRef"
                [ngTemplateOutletContext]="{ $implicit: data }"
              >
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="content.length === 0">
      <td class="container" colspan="12">
        <ng-content select="[no-content-grid]"></ng-content>
      </td>
    </ng-container>
  </tbody>
</cd-table>
