<cd-paging [page]="$page">
  <!-- <cd-paging-progress></cd-paging-progress> -->
   <div *ngIf="!showPagerOnly; else pagerOnly">
     <cd-paging-header *ngIf="showHeaderOnEmpty || (!showHeaderOnEmpty && content.length > 0)">
       <div left>
         <div class="d-flex chk-align">
           <ng-container *ngIf="this.mode === 'grid' && this.showSelectControls && content.length > 0">
             <div class="selection-section" [cprsSelectAll]="selectionKey"></div>
           </ng-container>
   
           <div class="column-fix">
             <ng-content select="[header-left]"></ng-content>
           </div>
         </div>
       </div>
       <div center>
         <!-- Paging Display -->
         <ng-content select="[header-center]"></ng-content>
         <div [class.d-none]="!showPagingDisplay || content.length === 0">
           <!-- <cd-paging-display class="noshow"></cd-paging-display> -->
           <!-- <cd-paging-display
             [pageStart]="manual.pageStart"
             [pageEnd]="manual.pageEnd"
             [total]="manual.total"
           ></cd-paging-display> -->
         </div>
       </div>
       <div right>
         <div class="column-fix d-flex">
           <ng-content select="[header-right]"></ng-content>
   
           <!-- Grid / Table toggle -->
           <div *ngIf="showModeToggle">
             <cd-button (click)="this.setViewMode('grid')" mode="text" size="small">
               <cd-icon [cdsIcon]="'list'"></cd-icon> Grid View
             </cd-button>
             <cd-button (click)="this.setViewMode('table')" mode="text" size="small" class="mx-1">
               <cd-icon [cdsIcon]="'table'"></cd-icon> Table View
             </cd-button>
           </div>
   
           <!-- Simple Pager -->
           <div *ngIf="showSimplePager" class="simple-pager">
             <cd-button mode="text" (btnClick)="prevPage()" [disabled]="isPrevDisabled()" size="small">
               <cd-icon [cdsIcon]="'caret-left'"></cd-icon> Previous
             </cd-button>
             <cd-button mode="text" (btnClick)="nextPage()" [disabled]="isNextDisabled()" size="small" class="ms-1">
               Next <cd-icon [cdsIcon]="'caret-right'"></cd-icon>
             </cd-button>
           </div>
         </div>
         <div class="d-flex">
           <div class="col d-flex align-items-center">
             <!-- Column Filtering by mode-->
             <ng-container *ngIf="this.mode === 'grid'">
               <form *ngIf="showColumnFiltering" [formGroup]="gridColumnForm" autocomplete="off" novalidate>
                 <cd-multi-select title="Columns" [showGivenTitle]="true">
                   <ng-container *ngFor="let column of this.modeColumns">
                     <li>
                       <cd-input-crs [formControlName]="column.columnKey" [label]="column.label"></cd-input-crs>
                     </li>
                   </ng-container>
                 </cd-multi-select>
               </form>
             </ng-container>
             <ng-container *ngIf="this.mode === 'table'">
               <form *ngIf="showColumnFiltering" [formGroup]="tableColumnForm" autocomplete="off" novalidate>
                 <cd-multi-select title="Columns" [showGivenTitle]="true">
                   <ng-container *ngFor="let column of this.modeColumns">
                     <li>
                       <cd-input-crs [formControlName]="column.columnKey" [label]="column.label"></cd-input-crs>
                     </li>
                   </ng-container>
                 </cd-multi-select>
               </form>
             </ng-container>
           </div>
         </div>
   
         <div *ngIf="sideColumnRef" class="side-column">
           <strong class="side-column-section">
             <ng-content select="[sideColumnHeader]"></ng-content>
           </strong>
         </div>
       </div>
     </cd-paging-header>
   </div>

  <ng-container *ngIf="this.mode === 'grid'">
    <app-cprs-grid
      [interface]="interface"
      [content]="content"
      [selectionKey]="selectionKey"
      [totalElements]="totalElements"
      [showIndexColumn]="showIndexColumn"
      [showSelectControls]="showSelectControls"
      [serverPage]="serverPage"
      (sortChange)="sortTable($event)"
      (pageChange)="triggerPageChangeEmit($event)"
      (viewModeChange)="viewModeChange.emit($event)"
      [fakePage]="fakePage"
      [clientPage]="clientPage"
      [pagingType]="pagingType"
      [headerTemplateRef]="headerTemplateRef"
      [sideColumnRef]="sideColumnRef"
    ></app-cprs-grid>
  </ng-container>

  <ng-container *ngIf="this.mode === 'table'">
    <app-cprs-table
      [interface]="interface"
      [content]="content"
      [selectionKey]="selectionKey"
      [totalElements]="totalElements"
      [showIndexColumn]="showIndexColumn"
      [showSelectControls]="showSelectControls"
      [serverPage]="serverPage"
      (sortChange)="sortTable($event)"
      [fakePage]="fakePage"
      [clientPage]="clientPage"
      (pageChange)="triggerPageChangeEmit($event)"
      (viewModeChange)="viewModeChange.emit($event)"
      [pagingType]="pagingType"
      [sortType]="sortType"
    ></app-cprs-table>
  </ng-container>

  <!-- Paging Footer -->
  <div [class.noshow]="content.length === 0">
    <cd-paging-footer>
      <ng-container ngProjectAs="[left]">
        <cd-paging-rows [pageSizes]="pageSizes" (pageChange)="goto($event)"></cd-paging-rows>
      </ng-container>
      <ng-container ngProjectAs="[center]">
        <cd-paging-range (pageChange)="goto($event)"></cd-paging-range>
      </ng-container>
      <ng-container ngProjectAs="[right]">
        <cd-paging-goto #goToRef (pageChange)="goto($event)"></cd-paging-goto>
      </ng-container>
    </cd-paging-footer>
  </div>
</cd-paging>

<ng-template #pagerOnly>
  <cd-paging-header *ngIf="showHeaderOnEmpty || (!showHeaderOnEmpty && content.length > 0)">
    <div center>
      <!-- Simple Pager -->
      <div *ngIf="showSimplePager" class="simple-pager">
        <cd-button mode="text" (btnClick)="prevPage()" [disabled]="isPrevDisabled()" size="small">
          <cd-icon [cdsIcon]="'caret-left'"></cd-icon> Previous
        </cd-button>
        <cd-button mode="text" (btnClick)="nextPage()" [disabled]="isNextDisabled()" size="small" class="ms-1">
          Next <cd-icon [cdsIcon]="'caret-right'"></cd-icon>
        </cd-button>
      </div>
    </div>
  </cd-paging-header>
</ng-template>