import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RenderTemplateForDownloadBody, TemplateService } from '@src/app/cprs/services/template.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { saveAs } from 'file-saver';
import { ElasticSearchResult } from '../../../../shared/interfaces/elastic-search.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { CdSearchService } from '@src/app/cprs/services/search.service';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { CentralErrorService } from '@app/cprs/services/central.error.service';
import { CdSelectionService } from '@src/app/cprs/modules/selection/services/selection.service';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss'],
})
export class DownloadDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  public downloadModal = true;
  public successModal = false;

  public selectedOption: 'pdf' | 'txt' | 'csv' | null = null;

  public formGroup = new UntypedFormGroup({
    records: new UntypedFormControl([]),
    template_type: new UntypedFormControl('search_results'),
    response_type: new UntypedFormControl('', Validators.required),
  });

  public error_message: string | undefined;

  public hasAcquisitionsOnDrv = false;
  public hasAcquisitionsSearchResults = false;

  public request_running = false;

  public data: {
    title: string;
    responseType: 'detail_record' | 'search_results';
    records: ElasticSearchResult[];
    control_numbers: string[];
  };

  public current_search: CprsSearch;

  constructor(
    public selectionService: CdSelectionService,
    public cprsService: CprsService,
    public cdSearchService: CdSearchService,
    public templateService: TemplateService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public centralErrorService: CentralErrorService
  ) { }

  ngOnInit() {
    this.cprsService.getCurrentSearch().subscribe((search) => {
      this.current_search = search;
    });
  }

  isRecentSearches(): boolean {
    return this.router.url.includes('recent-searches');
  }

  isSavedSearches(): boolean {
    return this.router.url.includes('saved-searches');
  }

  download() {
    const body: RenderTemplateForDownloadBody = this.formGroup.value;

    this.request_running = true;
    const records = this.data.control_numbers;
    body.template_type = this.data.responseType ?? 'detail_record';

    if (!records || records.length === 0) {
      this.error_message = 'Records required';
      this.formGroup.setErrors({ invalid: this.error_message });
      return;
    }

    const acquisitionsOnSearch = this.router.url.includes('search') ||
    this.router.url.includes('recent') || this.router.url.includes('associated') || this.router.url.includes('saved');
    this.hasAcquisitionsSearchResults = !!([
      this.selectionService.hasAcquisitions('search_results'),
      this.selectionService.hasAcquisitions('recent_records'),
      this.selectionService.hasAcquisitions('associated_records'),
      this.selectionService.hasAcquisitions('saved_records'),
    ].some(Boolean) && acquisitionsOnSearch);

    this.hasAcquisitionsOnDrv = [
      this.selectionService.hasAcquisitions('detailed_record'),
    ].some(Boolean);

    if ((this.hasAcquisitionsSearchResults || this.hasAcquisitionsOnDrv) && body.response_type === 'txt') {
      this.error_message = 'You are not able to download an acquisition record.'
      this.formGroup.setErrors({ invalid: this.error_message });
      return;
    }

    if (body.response_type === 'csv') {
      this.downloadCSV();
      return;
    }

    body.records = records;
    const subscription = this.templateService.render_template_for_download(body).subscribe(
      (response) => {
        this.request_running = false;
        this.downloadModal = false;
        saveAs(response, `Public Record(s).${body.response_type}`);
        if (!this.router.url.includes('detailed-record') && !this.router.url.includes('application-card')) {
          this.selectionService.deselectAllByKey('detailed_record');
        }
      },
      (error: HttpErrorResponse) => {
        this.centralErrorService.addError('Keycloak User Loading', error);
        this.request_running = false;
        this.error_message = error.error.detail ? error.error.detail : error.statusText;
        this.formGroup.setErrors({ invalid: this.error_message });
      }
    );
    this.subscriptions.push(subscription);
  }

  downloadCSV() {
    const control_numbers = this.data.control_numbers ?? [];
    this.cdSearchService.searchUniqueRecordCSV(control_numbers).subscribe(
      (resp) => {
        this.request_running = false;
        this.downloadModal = false;
        saveAs(resp, 'Public Record(s).csv');
      },
      (error) => {
        this.request_running = false;
        this.error_message = error.error.detail ? error.error.detail : error.statusText;
        this.formGroup.setErrors({ invalid: this.error_message });
      }
    );
  }

  close() {
    this.downloadModal = false;
    this.successModal = false;
  }

  selectOption(option: 'pdf' | 'txt' | 'csv') {
    if (this.formGroup.get('response_type')?.value === option) {
      this.formGroup.get('response_type')?.setValue(null);
      return;
    }

    this.formGroup.get('response_type')?.setValue(option);
  }

  isSelected(option: 'pdf' | 'txt' | 'csv') {
    return this.formGroup.get('response_type')?.value === option;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
