import { Component, OnInit, ViewChild } from '@angular/core';
import { CprsRecentSearch, RecentFeatureService } from '../../services/recent.service';
import { ButtonMenuComponent } from '@cop/design/components';
import { DialogService } from '@src/app/cprs/modules/dialogs/services/dialog.service';
import { DownloadDialogComponent } from '@src/app/cprs/modules/dialogs/components/download-dialog/download-dialog.component';
import { CprsDialogComponent } from '@src/app/cprs/modules/dialogs/components/cprs-dialog/cprs-dialog.component';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { CprsTableGrid } from '../../../table/models/cprs-table-grid.interface';
import { DatePipe } from '@angular/common';
import { SavedFeatureService } from '../../../saved/services/saved.feature.service';
import { FeatureFlagService } from '../../../shared/services/feature-flag/feature-flag.service';

@Component({
  selector: 'cprs-module-recent-searches',
  templateUrl: './recent-searches.component.html',
  styleUrls: ['./recent-searches.component.scss'],
})
export class RecentSearchesComponent implements OnInit {
  @ViewChild('actionsMenuButton')
  actionsMenuButton!: ButtonMenuComponent;

  public newTable = new CprsTableGrid('recentSearchesTable', 'List of Recent Searches', [
    {
      label: 'Search term',
      columnKey: 'search_term',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Field heading',
      columnKey: 'field_heading',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Filters added',
      columnKey: 'filters_added',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Number of results',
      columnKey: 'number_of_results',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
      pipe: 'number'
    },
    {
      label: 'Date searched',
      columnKey: 'timestamp',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
  ]);

  public mode: 'grid' | 'table' = 'table';

  public totalElements: number;

  public selection: CprsRecentSearch[] = [];

  public data: any[] = [];

  constructor(
    public recentService: RecentFeatureService,
    public cdSelectionService: CdSelectionService,
    public dialogService: DialogService,
    public savedService: SavedFeatureService,
    public featureFlagService: FeatureFlagService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.cdSelectionService.deselectAllByKey('recent_searches');

    this.recentService.getRecentSearches().subscribe((recent: CprsRecentSearch[]) => {
      this.data = recent
        .sort((a, b) => b['timestamp'].toLocaleString().localeCompare(a['timestamp'].toLocaleString()))
        .map((r) => this.getTableData(r));
      this.totalElements = this.data.length;
    });
  }

  email(): void {
    const records = this.cdSelectionService.getSelectedByKey('recent_searches');

    const simple_records = records.map((rs) => {
      return {
        searchLink: rs.url.value,
        searchTitle: rs.search_term.value,
      };
    });

    this.dialogService.open(CprsDialogComponent, {
      data: { title: 'Title', responseType: 'saved_or_recent', records: simple_records },
    });

    this.actionsMenuButton.closeDropdown();
  }

  download(): void {
    const searches = this.cdSelectionService.getSelectedByKey('recent_searches');

    const simple_records = searches.map((rs) => {
      return {
        searchLink: rs.url.value,
        searchTitle: rs.search_term.value,
      };
    });

    this.dialogService.open(DownloadDialogComponent, {
      data: { title: 'Title', responseType: 'search_results', control_numbers: simple_records },
    });

    this.actionsMenuButton.closeDropdown();
  }

  saveSearches(): void {
    const searches = this.cdSelectionService.getSelectedByKey('recent_searches');

    this.savedService.saveRecentSearches(searches);

    this.actionsMenuButton.closeDropdown();
  }

  clear(): void {
    const selected = this.cdSelectionService.getSelectedByKey('recent_searches');
    this.recentService.removeRecentSearches(selected);
    this.cdSelectionService.deselectAllByKey('recent_searches');
    this.totalElements = this.data.length;
    this.actionsMenuButton.closeDropdown();
  }

  selectionChange($event: any[]) {
    this.selection = $event as CprsRecentSearch[];
  }

  getTableData(recent_search: CprsRecentSearch) {
    return {
      search_term: {
        value: recent_search.search_term,
        url: recent_search.url,
      },
      field_heading: {
        value: recent_search.field_heading,
      },
      filters_added: {
        value: recent_search.filters_added,
      },
      number_of_results: {
        value: recent_search.number_of_results,
      },
      date_string: {
        value: recent_search.date_string,
      },
      url: {
        value: recent_search.url,
      },
      timestamp: {
        value: this.datePipe.transform(recent_search.timestamp, 'yyyy-MM-dd hh:mm a'),
      },
    };
  }
}
