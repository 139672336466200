<cprs-base-page [hideSearch]="true">
  <div class="my-4">
    <h1 class="display-3">Name Directory</h1>
    <div class="my-2">
      The Name Directory is an alphabetical listing of the names electronically indexed from records in the Copyright
      Public Record System. Clicking on a name in the Name Directory will show a new page of records associated with
      that specific name.
    </div>
  </div>

  <div class="name-directory-search mb-4">
    <div class="display-8">Lookup name</div>
    <div class="display-7">Last name, first name</div>
    <div class="col-4">
      <form class="row g-3" [formGroup]="search2Form" (ngSubmit)="search()" novalidate autocomplete="off">
        <div class="col-12">
          <cd-search [queryControlName]="'query'"> </cd-search>
        </div>
      </form>
    </div>
  </div>

  <cprs-errors [key]="'name_directory'"></cprs-errors>

  <div *appLoading class="name-directory-table">
    <cprs-table-grid
      [interface]="nameDirectoryTable"
      [mode]="mode"
      pagingType="server"
      [serverPage]="this.searchPage"
      (pageChange)="this.pageChange($event)"
      [content]="data"
      [showPagingDisplay]="true"
      [showIndexColumn]="false"
      [showSelectControls]="false"
      [showSimplePager]="true"
      [showPagerOnly]="true"
    >
      <div no-content>Once you have performed a search, found names will be available here.</div>
    </cprs-table-grid>
  </div>
</cprs-base-page>
