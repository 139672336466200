<div class="row g-0 align-items-end">
  <div class="search-form col-sm-12 col-md-8 flex-grow-1">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <cd-search
        [queryControlName]="'query'"
        [optionsControlName]="'keywords'"
        [options]="basicSearchTypeOptions"
      ></cd-search>
    </form>
  </div>
  <div class="search-links col-sm-12 col-md-4 fit-content">
    <a
      class="d-block mt-2 mt-md-0 mx-md-3 ml-lg-0"
      [class.text-white]="linkColor === 'secondary'"
      routerLink="/advanced-search"
    >
      Advanced Search
    </a>
  </div>
</div>
