<cprs-base-page [hideSearch]="true">
  <h1 class="display-3 title">Associated Records</h1>

  <div>
    <div>
      Names for individuals and organizations may be spelled in multiple ways. View the
      <a class="text-decoration-underline" routerLink="/name-directory">Name Directory</a> to see similar names.
    </div>
    <div>Example: Disney and Disney Productions will be two separate rows.</div>
  </div>

  <div class="display-5 my-4">
    <a class="org-name" routerLink="/name-directory/{{ this.id }}">{{ this.id }}</a>
  </div>

  <cprs-errors [key]="'associated_records'"></cprs-errors>

  <div *appLoading class="associated-records-table">
    <cprs-table-grid
      [interface]="associatedRecordsTable"
      [mode]="mode"
      pagingType="server"
      [serverPage]="this.serverPage"
      (pageChange)="this.pageChange($event)"
      [selectionKey]="'associated_records'"
      [content]="data"
      [showPagingDisplay]="true"
      [showSimplePager]="true"
    >
      <div header-left>
        <cd-button-menu title="Actions" [placement]="'bottom-start'" #actionsMenuButton>
          <li (cdMenuTextButton)="email()">Email</li>
          <li (cdMenuTextButton)="download()">Download</li>
        </cd-button-menu>
      </div>

      <div no-content-table>There are no associated records for the provided name.</div>
    </cprs-table-grid>
  </div>
</cprs-base-page>
