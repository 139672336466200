<div id="landing-page">
  <section>
    <!-- Landing banner box - contains all the functionality -->
    <section class="landing-banner">
      <!-- Landing banner box - contains all the functionality -->
      <div class="landing-banner-box">
        <div class="display-6 d-flex justify-content-center mb-2">
          <span class="text-white"> {{ this.text.header2 }}</span>
          <!-- <cd-icon
            [classes]="'px-2'"
            [cdsIcon]="'info-circle'"
            placement="right"
            ngbTooltip="When searching for more than one word, simple search places an AND between each word."
          >
          </cd-icon> -->
          <cd-popover [ariaLabel]="'Search'">
            <span>When searching for more than one word, simple search places an AND between each word.</span>
          </cd-popover>
        </div>
        <cprs-search linkColor="secondary"></cprs-search>
        <div class="text-white display-7 mt-2">{{ this.text.search_example_text }}</div>
      </div>
    </section>
  </section>
  <section class="landing-body my-3">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-12">
          <div class="landing-banner-section px-2">
            <h4 class="pb-2 display-4">Welcome to the U.S. Copyright Office Public Records System - Pilot</h4>
            <p class="copyright-content py-3">
              The Copyright Public Records System (CPRS) is a pilot that will run in parallel with the
              <a href="https://cocatalog.loc.gov/cgi-bin/Pwebrecon.cgi?DB=local&PAGE=First"
                >Copyright Public Catalog.</a
              >
              The CPRS uses a more powerful search engine than the Copyright Public Catalog, provides easy filtering
              capability, and follows user-centered design principles in line with the
              <a href="https://www.copyright.gov/copyright-modernization/">Enterprise Copyright System.</a> In addition
              to information that appears in the official Copyright Public Catalog, the CPRS now also includes images
              and searchable metadata from an initial release of card catalog registration applications from the
              1909–1945 period. As the Office continues its modernization journey, more records and metadata will be
              added on a regular basis. Note that there may be a delay of a day or more in the results displayed in this
              system as compared to the online public catalog.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <p class="home-page-img-class mx-md-4"></p>
        </div>
      </div>
    </div>
  </section>
  <section class="landing-body my-3 availabel-record">
    <div class="container">
      <div class="row landing-banner-section py-3">
        <h4 class="display-4">What will I find in a Copyright Public Records System?</h4>
        <div class="col-md-6 col-sm-12 px-4">
          <div class="icon-cls">
            <cd-icon [classes]="'fs-2 text-success'" [cdsIcon]="'check'"></cd-icon>
            <strong class="ps-2 labelSize">Available in the record</strong>
          </div>
          <div class="ps-3">
            <ul style="list-style-type: disc" class="mt-3">
              <li>Registration and recordation records from 1978 to the present</li>
              <li>Catalog card records with images of the 1909–1945 application cards</li>
              <li>Information about the author, claimant, parties, relevant dates, etc.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 px-4 not-available-text">
          <div class="icon-cls">
            <cd-icon [classes]="'fs-2 text-danger'" [cdsIcon]="'ban'"></cd-icon>
            <strong class="ps-2 labelSize">Not available in the record</strong>
          </div>
          <div class="ps-3">
            <ul style="list-style-type: disc" class="mt-3">
              <li>
                Physical copies of the work (You can request copies via
                <a href="https://www.copyright.gov/rrc/"> Records Research and Certification</a>).
              </li>
              <li>Reproductions (audio, visual, or text) of the work</li>
              <li>Registration application status</li>
              <li>
                Notification of court filings and determination of actions under section 508 (You can request copies via
                <a href="https://www.copyright.gov/rrc/">Records Research and Certification</a>).
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="landing-body">
    <div class="container ps-0">
      <!-- Secondary informational section -->
      <h4 class="p-2 display-4">Other resources</h4>
      <div class="landing-secondary">
        <!-- Other cards -->
        <div class="landing-secondary-content">
          <ng-container *ngFor="let card of this.cards">
            <cd-nav-card
              width="320px"
              height="100%"
              identifier="Card - Image"
              link="{{ card.action.link }}"
              title="{{ card.title }}"
            >
              <img #image image="" src="{{ card.image.resource }}" alt="" class="img-class" />
              <div #body body>
                {{ card.bodyText }}
                <!-- <div #links links style="position: absolute; bottom: 10px">
                  <a rel="noopener noreferrer" target="_blank">{{ card.action.label }}</a>
                </div> -->
              </div>
            </cd-nav-card>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>
