import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Page, PageImpl } from '@cop/design/services';
import { BehaviorSubject } from 'rxjs';
import { CdTableData, CdTableHeader, CprsTableGrid } from '../../models/cprs-table-grid.interface';
import { SortEvent } from '@cop/design/table';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { Router } from '@angular/router';
import { ParametersService } from '@src/app/cprs/services/parameters.service';

@Component({
  selector: 'app-cprs-table',
  templateUrl: './cprs-table.component.html',
  styleUrls: ['./cprs-table.component.scss']
})
export class CprsTableComponent implements OnInit, OnChanges {
  // @ViewChild('goToRef', { static: false }) goToRef: PagingGotoComponent;

  @ContentChild('header', { static: false }) headerTemplateRef: TemplateRef<any>;

  @ContentChild('sideColumn', { static: false }) sideColumnRef: TemplateRef<any>;

  @Input() interface: CprsTableGrid;

  @Input() mode: 'grid' | 'table' = 'table';

  @Input() sortType: 'client' | 'server' = 'client';

  @Input() pagingType: 'client' | 'server' = 'client';

  @Input() pageSizes: number[] = [10, 25, 50, 100];

  @Input() selectionKey: string;

  @Input() content: any[] = [];

  @Input() totalElements: number;

  @Input() showColumnFiltering: boolean = false;

  @Input() showPagingDisplay: boolean = false;

  @Input() showSimplePager: boolean = false;

  @Input() showModeToggle: boolean = false;

  @Input() showIndexColumn: boolean = true;
  @Input() showSelectControls: boolean = true;

  @Input() showHeaderOnEmpty: boolean = true;

  @Input() serverPage: BehaviorSubject<Page<any>>;

  @Input() serverSorting: boolean = false;

  @Output() sortChange = new EventEmitter<SortEvent>();

  @Output() pageChange = new EventEmitter<Page<any>>();

  @Output() viewModeChange = new EventEmitter<'grid' | 'table'>();

  public selectionForm: UntypedFormGroup;

  @Input() clientPage: BehaviorSubject<Page<any>>;

  @Input() fakePage = PageImpl.empty();

  columnForm: UntypedFormGroup;
  gridColumnForm: UntypedFormGroup;
  tableColumnForm: UntypedFormGroup;

  visibleColumns: string[];
  modeColumns: any[];

  public manual = {
    pageStart: 0,
    pageEnd: 0,
    total: 0,
  };

  constructor(public router: Router, public selectionService: CdSelectionService, public parameterService: ParametersService) {
    this.fakePage.size = 10;
    this.fakePage.totalPages = 1;
    this.fakePage.first = true;
    this.fakePage.isLoaded = true;
    this.fakePage.numberOfElements = this.fakePage.size;
  }

  /*
    Prepare table data based on the interfae
      - CD3.0 requires a formgroup and visible columns
  */
  ngOnInit(): void {
    if (this.interface) {
      this.updateColumns();
    }

    if (this.selectionKey) {
      this.selectionForm = this.selectionService.getSelectionGroup(this.selectionKey);
    }

    if (this.pagingType === 'server') {
      this.serverPage.subscribe((page) => {
        if (this.selectionForm) {
          const pageForm = this.selectionForm.get('page');
          if (pageForm) {
            pageForm.setValue(page);
          }
        }
        this.fakePage.size = page.size;
        this.fakePage.number = page.number;
        this.fakePage.totalElements = page.totalElements;
        this.fakePage.totalPages = page.totalPages;
        this.updatePagingDisplay(page);
      });
    } else {
      this.clientPage?.subscribe((page) => {
        this.updatePagingDisplay(page);
        this.pageChange.emit(page);
      });

      this.parameterService.route.queryParamMap.subscribe(queryParamMap => {
        const size = Number(queryParamMap.get('records_per_page') ?? 10);
        const number = Number(queryParamMap.get('page_number') ?? 0);

        this.fakePage.number = number;
        this.fakePage.size = size;
      })
      this.pageChange.emit(this.fakePage);
    }
  }

  get $page() {
    return this.pagingType === 'client' ? this.clientPage : this.serverPage;
  }

  updatePagingDisplay(page: Page<any>, constant = 0) {
    // TODO MANUAL
    this.manual.total = page.totalElements;
    if (page.number === 0) {
      this.manual.pageStart = 1;
    } else {
      this.manual.pageStart = (Number(page.number) + constant) * Number(page.size) + 1;
    }
    this.manual.pageEnd = (Number(page.number) - constant) * Number(page.size) + Number(page.size);
    if (this.manual.total <= this.manual.pageEnd) {
      this.manual.pageEnd = this.manual.total;
    }

    this.manual.pageEnd = 12;
  }

  updateColumns() {
    const formGroup = new UntypedFormGroup({});
    const tableColumns = this.interface.columns
      .filter((c) => c.visibility.table && c.visibility.visible)
      .map((c) => c.columnKey);
    this.interface.columns
      .filter((c) => c.visibility.table)
      .forEach((c) => formGroup.addControl(c.columnKey, new UntypedFormControl(c.visibility.visible)));
    this.modeColumns = this.interface.columns.filter((c) => c.visibility.table);

    this.tableColumnForm = formGroup;
    this.visibleColumns = tableColumns;

    this.tableColumnForm.valueChanges.subscribe((columns) => {
      this.visibleColumns = Object.keys(columns).filter((col) => columns[col] === true);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['fakePage']) {
      this.fakePage = changes['fakePage']?.currentValue
      const pageStart = this.fakePage.number * this.fakePage.size + 1;
      if (this.fakePage.totalElements < pageStart) {
        this.fakePage.number = this.fakePage.number - 1 > 0 ? this.fakePage.number - 1 : 0;
      }
      if (this.totalElements <= this.fakePage.numberOfElements) {
        this.fakePage.numberOfElements = this.fakePage.totalElements;
      }
      this.clientPage?.next(this.fakePage);
      this.serverPage?.next(this.fakePage);
    }

    if (changes && changes['mode']) {
      this.updateColumns();
    }
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], { queryParams: params });
      return;
    }

    this.router.navigateByUrl(url);
  }

  goto($event: any) {
    this.fakePage = $event;
    this.fakePage.numberOfElements = $event.size;
    this.fakePage.totalElements = $event.totalElements;
    this.fakePage.totalPages = Math.ceil(this.fakePage.totalElements / $event.size);
    this.fakePage.first = $event.number <= 0;
    this.fakePage.last = $event.number + 1 >= this.fakePage.totalPages;
    this.fakePage.content = [];
    this.fakePage.isLoaded = true;

    if (this.pagingType === 'client') {
      if (this.content.length < this.fakePage.numberOfElements) {
        this.fakePage.numberOfElements = this.content.length;
      }

      this.selectionForm.get('page')?.setValue(this.fakePage);
      this.clientPage.next(this.fakePage);

      this.parameterService.addQueryParameters({
        page_number: this.fakePage.number,
        records_per_page: this.fakePage.size
      });
    }

    this.pageChange.emit($event);
  }

  sortTable($event: any) {
    if (this.sortType === 'client') {
      this.genericSort($event);
    } else {
      this.sortChange.emit($event);
    }
  }

  get columnKeys(): string[] {
    return Object.keys(this.columnForm.controls);
  }

  isResizable(column: CdTableHeader): boolean {
    return column.resizable ?? false;
  }

  getSortDefault(column: CdTableHeader): 'asc' | 'desc' {
    return column.sortDefault ?? 'asc';
  }

  getIndex(index: number) {
    return this.fakePage.number * this.fakePage.size + index + 1;
  }

  public genericSort($event: SortEvent) {
    this.content.sort((a: CdTableData, b: CdTableData) => {
      let comparison = 0;

      const valueA = a[$event.name]['value'];
      const valueB = b[$event.name]['value'];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // compare string values
        comparison = valueA.localeCompare(valueB);
      } else if (valueA instanceof Array && valueB instanceof Array) {
        // compare arrays by JSON
        const jsonA = JSON.stringify(valueA);
        const jsonB = JSON.stringify(valueB);
        comparison = jsonA.localeCompare(jsonB);
      } else {
        // default
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        } else {
          comparison = 0;
        }
      }

      return $event.direction === 'asc' ? comparison : -comparison;
    });
  }
}
