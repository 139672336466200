import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabComponent } from '@cop/design/components';
import { CdSelectionService } from '../../modules/selection/services/selection.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Page } from '@cop/design/services';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { CprsService } from '../../services/cprs.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-save',
  templateUrl: './manage-save.component.html',
  styleUrls: ['./manage-save.component.scss'],
})
export class ManageSaveComponent implements OnInit {
  public current_tab: string | undefined = 'saved-records';

  recordsText?: string;
  savedRecordsPage: any;
  public total = 0;
  public totalSearches = 0;

  public manualPagingDisplay = {
    pageStart: 0,
    pageEnd: 0,
    total: 0,
  };

  public searchesPaginDisplay = {
    pageStart: 0,
    pageEnd: 0,
    total: 0,
  };

  constructor(
    private router: Router,
    public cdSelectionService: CdSelectionService,
    public breadcrumbService: BreadcrumbService,
    public cprsService: CprsService,
    public titleService: Title,
  ) { }

  ngOnInit(): void {
    this.recordsText = 'Saved Records';

    this.cprsService.clearState();

    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = this.recordsText;

    const saved_records: UntypedFormGroup = this.cdSelectionService.getSelectionGroup('saved_records');
    const saved_records_page: UntypedFormControl = saved_records.get('page') as UntypedFormControl;

    saved_records_page.valueChanges.subscribe((page: Page<any>) => {
      this.total = page.totalElements;

      this.manualPagingDisplay.total = this.total;
      this.manualPagingDisplay.pageStart = page.number * page.size + 1;
      this.manualPagingDisplay.pageEnd = page.number * page.size + page.size;
      if (this.manualPagingDisplay.total <= this.manualPagingDisplay.pageEnd) {
        this.manualPagingDisplay.pageEnd = this.manualPagingDisplay.total;
      }
    });

    const saved_searches: UntypedFormGroup = this.cdSelectionService.getSelectionGroup('saved_searches');
    const saved_searches_page: UntypedFormControl = saved_searches.get('page') as UntypedFormControl;

    saved_searches_page.valueChanges.subscribe((page: Page<any>) => {
      this.totalSearches = page.totalElements;

      this.searchesPaginDisplay.total = this.totalSearches;
      this.searchesPaginDisplay.pageStart = page.number * page.size + 1;
      this.searchesPaginDisplay.pageEnd = page.number * page.size + page.size;
      if (this.searchesPaginDisplay.total <= this.searchesPaginDisplay.pageEnd) {
        this.searchesPaginDisplay.pageEnd = this.searchesPaginDisplay.total;
      }
    });
  }

  savedRecords(tab: TabComponent) {
    this.recordsText = tab.tabTitle;
    this.current_tab = tab.id;
    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = this.recordsText ?? '';
    this.router.navigate([`/saved/${tab.id}`], {
      queryParams: {
        page_number: 0,
        records_per_page: 10
      }
    }).then();
    // this.router.navigate([`/saved/${tab.id}`]).then();
    this.titleService.setTitle(
      `${this.recordsText} | U.S. Copyright Public Records System`
    );
  }
}
