<cprs-base-page>
  <h1 class="display-3">Help</h1>
  <div class="mb-4">
    The Copyright Public Records System offers simple and advanced searches. Click an area below to expand a section for
    more details about how each search functions.
  </div>

  <section class="simple-search" id="simple-search">
    <h2 class="display-4">Simple Search</h2>
    <hr />
    <div class="help-row-container">
      <div class="help-column">
        <p>
          The homepage search bar performs a Simple Keyword Search. You can enter Registration Numbers, Document
          Numbers, or Standard Identifiers, such as ISBN, ISSN, or ISRC, using Keyword Search.
        </p>
        <p>
          The dropdown also allows you to search by Name and Title. Names include authors, claimants, publishers, and
          parties to recorded documents. Names can be entered by first and last, such as Mary Smith. Titles are the
          works registered with the office or indexed in documents recorded with the Office.
        </p>
        <p>Results will be ranked by relevance.</p>
      </div>
      <div class="help-column">
        <h3 class="display-5">Simple Search Logic</h3>
        <p>When conducting a search that includes multiple words, simple search places an AND in-between each word.</p>

        <span>Example:</span>
        <p>Search term: Indiana Jones becomes Indiana AND Jones</p>
        <div class="full-title-container">
          <div class="full-title">
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones Trilogy</div>
          </div>
          <div class="full-title">
            <div class="example-box"><strong>Full Title: </strong>The story of Indiana post office</div>
          </div>
        </div>
        <div class="full-title-container">
          <div class="full-title">
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones: The Ultimate Guide</div>
          </div>
          <div class="full-title"></div>
        </div>
      </div>
      <div class="full-title-container">
        <div class="full-title">
          <h3 class="display-5">Special Simple Search Terms</h3>
          <p>Searching for * will pull all available records.</p>
        </div>
      </div>
    </div>
  </section>

  <section class="advanced-search" id="advanced-search">
    <h2 class="display-4">Advanced Search - Understanding Field Headings and Search Types</h2>
    <hr />
    <div class="help-row-container">
      <div class="help-column">
        <h3 class="display-5">Field Headings</h3>
        <p>Advanced Search allows you to direct your search to a specific field heading.</p>
        <p>
          Keyword searches anywhere in the record. All other selections will focus on specific fields e.g. if you select
          Party 1 then enter a name only results where that name appears in Party 1 will appear.
        </p>
        <p>
          All Names, All Copyright Numbers and All Standardized Numbers will look up under multiple fields associated
          with that category.
        </p>
      </div>
    </div>
    <div class="help-row-container">
      <div class="help-column">
        <h3 class="display-5">Search Types</h3>
        <p>Advanced Search provides three search types that provide different results.</p>
        <p>
          “As A Phrase” searches for your search terms in the order you have entered them anywhere within the specified
          field(s).
        </p>
        <p>
          "Starts with" searches for your search terms in the order you have entered them at the beginning of the
          specified field(s). This may also be known as a “Left Anchored” Search.
        </p>
        <p>
          “Is Exact” searches for your exact terms and only those terms in that order in the specified field(s). This
          search will produce a very narrow set of results.
        </p>
      </div>
    </div>

    <div class="help-row-container width-75">
      <div class="help-column">
        <span>Example:</span>
        <p>Search results for the term Indiana Jones in Title would include these records based on the search type:</p>
      </div>
    </div>
    <div class="help-row-container width-75">
      <!-- <div class="help-column">
            <strong>Contains</strong>
            <div class="full-title-container">
              <div class="full-title">
                <div class="example-box"><strong>Full Title: </strong>Raiders march: Indiana Jones</div>
                <br />
                <div class="example-box"><strong>Full Title: </strong>Indiana Jones and the Temple of Doom</div>
                <br />
                <div class="example-box"><strong>Full Title: </strong>Indiana Jones</div>
                <br />
                <div class="example-box"><strong>Full Title: </strong>Indiana Law / Mr. Jones</div>
              </div>
            </div>
          </div> -->
      <div class="help-column">
        <strong>As A Phrase</strong>
        <div class="full-title-container">
          <div class="full-title">
            <div class="example-box"><strong>Full Title: </strong>Raiders march: Indiana Jones</div>
            <br />
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones and the Temple of Doom</div>
            <br />
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones</div>
          </div>
        </div>
      </div>
    </div>
    <div class="help-row-container w-75">
      <div class="help-column">
        <strong>Starts with</strong>
        <div class="full-title-container">
          <div class="full-title">
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones and the Temple of Doom</div>
            <br />
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones</div>
          </div>
        </div>
      </div>
      <div class="help-column">
        <strong>Is Exact</strong>
        <div class="full-title-container">
          <div class="full-title">
            <div class="example-box"><strong>Full Title: </strong>Indiana Jones</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="name-directory" id="name-directory">
    <h2 class="display-4">Name Directory - Lookup and Associated Records</h2>
    <hr />
    <div class="help-row-container">
      <div class="help-column">
        <p>
          The Name Directory feature allows you to lookup any individual or organization in the Copyright Public Record
          System<span class="display-4">*</span>. Names must be searched by Last name, First Name and are listed in the
          same way as written in the registration or recordaion application.
        </p>
        <p>
          Every name listed has a corresponding number of records associated with it. By clicking on the name, you can
          view records where that name appears. Each record listed on the Associated Records page is linked.
        </p>
        <p>
          <span class="display-4">*</span>Although metadata from the card catalog can be searched by Name, Author,
          Claimant, or Publisher, those names are not yet included in the Name Directory.
        </p>
      </div>
      <div class="help-column"></div>
    </div>
    <div class="help-row-container">
      <div class="help-column">
        <strong>Name Directory List View</strong>
        <p>
          <img alt="list view" src="../../../../assets/images/list_view.png" />
        </p>
      </div>
      <div class="help-column">
        <strong>Associated Record View</strong>
        <p>
          <img alt="associated record" src="../../../../assets/images/associated_record.png" />
        </p>
      </div>
    </div>
  </section>

  <section class="search-results" id="search-results">
    <h2 class="display-4">Search Results</h2>
    <hr />
    <div class="help-row-container">
      <div class="help-column">
        <p>
          Once you have executed your search, you will get a set of search results that you will be able to filter to
          further narrow your results.
        </p>
        <br />
        <p>The System displays the 50,000 most relevant search results.</p>

        <h3 class="display-5">Sorting search results</h3>
        <p>
          By default, results are sorted by relevance. Results may also be sorted alphabetically by title or sorted by
          newest or oldest date.
        </p>

        <h3 class="display-5">Actions</h3>
        <p>
          If you select one or more records, you will be able to perform actions on those records such as emailing or
          downloading the selected record(s). Download options include Text, PDF, or CSV.
        </p>

        <h3 class="display-5">Filtering Search Results</h3>
        <h4 class="display-6">Date Filters</h4>
        <p>
          To filter by date set the date range by entering “Start Date” and “End Date” and click “Apply.” The default
          "Dates” selection covers the most common dates found in copyright records.
        </p>
        <p>
          You may also filter by specific date types found in our records such as Effective Date of Registration, Date
          of Creation, Date of Publication, Date of Recordation, or Date of Certification. Please keep in mind that some
          of these dates may not be present in all records.
        </p>
        <p>Date filters may be cleared by clicking the button.</p>

        <!-- <p>
          <strong>To remove the filters, you can click on “Clear filter(s)”.</strong>
        </p>

        <div class="display-6">Filtering search results</div>
        <p>
          Learn more about Copyright Records at the
          <a href="https://www.copyright.gov/public-records/" rel="nofollow noopener" target="_blank"
            >Copyright Public Records Portal</a
          >.
        </p> -->
        <h4 class="display-6">Type/Attribute Filters</h4>
        <p>
          The CPRS provides facets and filters associated with the underlying metadata for each record. The facets and
          filters are intended to help narrow search results and may not be exhaustive.
          <strong>Please note</strong> that the metadata collected by the office varies over time due to changes in
          copyright law, cataloging practices, and available information at the time the record was created.
        </p>
        <p>
          To filter your results, select the filter(s) to the right of the results. You may filter by the following
          metadata:
        </p>

        <h3 class="display-5">Collection</h3>
        <p>
          This facet allows you to narrow your search results to only include results from either the post-1977 records
          available in the current Public Catalog, or the digitized card catalog collection.
        </p>
        <h4 class="display-6">Post-1977 Records</h4>
        <p>
          These records contain the same metadata that is currently available in the Public Catalog for records
          submitted to the office after the
          <a href="https://www.copyright.gov/timeline/timeline_1950-2000.html">Copyright Act of 1976</a> . While most
          records will have a date that is after January 1, 1978, the effective date of the law, there may be some
          records with dates before that time.
        </p>
        <h4 class="display-6">Card Catalog Records</h4>
        <p>
          The Office is digitizing registration application cards from the card catalog and making them available online
          through the CPRS. New files are added on a weekly basis. Card images are displayed alongside searchable
          metadata. For more information see the
          <a href="https://copyright.gov/historic-records/">Historical Public Records Program </a> or the Copyright At
          Work Blog entry
          <a
            href="https://blogs.loc.gov/copyright/2023/11/over-one-million-card-catalog-records-digitized-in-copyright-public-records-system-pilot/"
            >Over One Million Card Catalog Records Digitized in Copyright Public Records System Pilot.</a
          >
        </p>
        <h3 class="display-5">Record Type</h3>
        <p>This facet allows you to narrow your search results to Registration Records or Recorded Documents.</p>
        <h4 class="display-6">Registration Status</h4>
        <p>
          This facet allows you to narrow your search results to records that can be identified as published or
          unpublished.
        </p>
        <h4 class="display-6">Registration Class</h4>
        <p>
          This facet allows you to narrow your search results to include results that have metadata matching a known
          registration class. For more information, please see
          <a href="https://copyright.gov/historic-records/admin-classification.pdf"
            >Administrative Copyright Classification Systems.</a
          >
        </p>
        <h4 class="display-6">Registration Item Type</h4>
        <p>
          This facet allows you to narrow your search results to include results that have metadata matching a known
          registration "Type of Work.”
        </p>
        <h4 class="display-6">Recordation Document Type</h4>
        <p>
          This facet allows you to narrow your search results to include results that have metadata matching a known
          recorded document type.
        </p>
        <div>
          <ul>
            <li><strong> To remove any of the filters, you can click on “Clear filter(s).”</strong></li>
          </ul>
        </div>
        <p>
          Learn more about Copyright Records at the
          <a href="https://www.copyright.gov/public-records/">Copyright Public Records Portal.</a>
        </p>
      </div>
      <div></div>
    </div>
  </section>
</cprs-base-page>
