import { Router } from '@angular/router';
import { EnvService } from '../../shared/services/env/env.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { CprsTableGrid } from '../../table/models/cprs-table-grid.interface';
import { ElasticSearchHighlight, ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { BehaviorSubject } from 'rxjs';
import { Page, PageImpl } from '@cop/design/services';
import { StateService } from '@src/app/cprs/state/state.service';
import { SavedFeatureService } from '../../saved/services/saved.feature.service';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';
import { CustomAuthGuardService } from '@src/app/cprs/modules/system/guards/custom-auth-guard.service';
import { SortEvent } from '@cop/design/table';

@Component({
  selector: 'cprs-module-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  public viewMode: 'grid' | 'table' = 'grid';

  /*
    Table / Grid have a big problem, where the grid has changable labels
    but the table is fixed, boolean toggle for tables / grid columns?
  */
  public newTable = new CprsTableGrid(
    'searchResultsTable',
    'List of Search Results',
    [
      {
        label: 'Full Title',
        columnKey: 'full_title',
        visibility: { grid: true, table: true, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-6',
      },
      {
        label: 'Copyright Number',
        columnKey: 'copyright_number',
        visibility: { grid: true, table: true, visible: true },
        visible: true,
        sortable: false,
        classes: 'col-2',
      },

      {
        label: 'Date',
        columnKey: 'representative_date',
        visibility: { grid: true, table: true, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'Type',
        columnKey: 'acquisition_type',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },

      {
        label: 'Type of Work',
        columnKey: 'type_of_work',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'Owner',
        columnKey: 'owner',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'Party 1',
        columnKey: 'party_1',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'Publisher Name',
        columnKey: 'publisher_name',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },

      {
        label: 'Claimant',
        columnKey: 'claimant',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'Party 2',
        columnKey: 'party_2',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'Publication Date',
        columnKey: 'publication_date',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
      {
        label: 'SR number',
        columnKey: 'service_request_number',
        visibility: { grid: true, table: false, visible: true },
        visible: true,
        sortable: true,
        classes: 'col-2',
      },
    ],
    true
  );

  public content: any[] = [];

  public contentObservable = new BehaviorSubject<any[]>([]);

  public searchPage = new BehaviorSubject(PageImpl.empty());

  public $page = PageImpl.empty();

  public totalElements = 50_000;

  public selectionForm = new UntypedFormGroup({
    selection: new UntypedFormControl([]),
  });

  public current_search: CprsSearch | null;
  public isLoading: boolean;

  public isSaving: boolean;
  public isLoggedIn: boolean;

  public manual = {
    pageStart: 0,
    pageEnd: 0,
    total: 0,
  };

  constructor(
    public cprsService: CprsService,
    public stateService: StateService,
    public featureFlagService: FeatureFlagService,
    public savedFeatureService: SavedFeatureService,
    public customAuthguard: CustomAuthGuardService,
    public envService: EnvService,
    public router: Router
    // private titleCasePipe: TitleCasePipe
  ) {
    this.$page.size = 10;
    this.$page.numberOfElements = 10;
    this.searchPage.next(this.$page);

    this.customAuthguard.loggedIn().subscribe(logged => {
      this.isLoggedIn = logged;
    })
  }

  isSaved(data: any) {
    return this.savedFeatureService.isSaved(data?.control_number?.value);
  }

  getSaved(data: any) {
    // const saved = this.savedFeatureService.getSavedRecord(data?.control_number?.value);
    // console.log('data', saved);
    return data;
  }

  saveRecord(data: any) {
    const control_number = data?.control_number?.value;

    data.saving.label = 'Saving';
    data.saving.value = true;

    this.savedFeatureService.saveRecords(control_number).subscribe(() => {
      data.saving.value = false;
    }, () => {
      data.saving.value = false;
    });
    // this.savedFeatureService.newSaveRecord(control_number).subscribe(saved => {
    //   this.savedFeatureService.reload().subscribe(() => {
    //     console.log(saved);
    //     data.saving.value = false;
    //   })
    // })

    // deprecated
    // this.savedFeatureService.saveRecordByControlNumbers([esr?.get('control_number')]);
  }

  removeRecord(data: any) {
    const control_number = data?.control_number?.value;

    data.saving.label = 'Removing';
    data.saving.value = true;

    // this.savedFeatureService.newClearRecords([data?.control_number?.value]).subscribe(() => {

    //   this.savedFeatureService.reload().subscribe(() => {
    //     data.saving.value = false;
    //   });
    // })

    this.savedFeatureService.removeRecords(control_number).subscribe(() => {
      data.saving.value = false;
    }, () => {
      data.saving.value = false;
    });

    // return this.savedFeatureService.clearRecordsByControlNumbers([data?.control_number?.value]).subscribe(() => {
    //   data.saving.value = false;
    // })
  }

  saveSearch() {
    if (this.current_search) {
      this.savedFeatureService.saveSearch(this.current_search).subscribe();
    }
  }

  pageChange($page: Page<any>) {
    this.cprsService.$page = $page;
    this.cprsService.searchPage.next($page);
    this.stateService.pagination.get('rows_per_page')?.setValue($page.size);
    this.stateService.pagination.get('page_number')?.setValue($page.number + 1);
    if (this.current_search) {
      if (this.router.url.includes('/advanced-search')) {
        this.current_search.type = 'advanced';
      } else {
        this.current_search.type = 'simple';
      }
    }
    this.cprsService.search(this.current_search?.type);
  }

  ngOnInit(): void {
    // not implemented yet
    this.cprsService.loading.subscribe((loading) => {
      this.isLoading = loading;
    });

    this.setViewMode(this.stateService._state.get('mode')?.value ?? 'grid');

    // this.searchResultsTableService.contentObservable.subscribe(content => {
    //   this.content = content;
    // });

    // this.cprsService.testSubject.subscribe((resp) => {
    //   this.content = resp?.response.data.map((d) => this.getData(d)) ?? [];
    //   const search = resp as CprsSearch;
    //   if (!search) {
    //     return;
    //   }
    //   this.manual.total = search.response.metadata.hit_count;
    //   if (search.parameters.page_number === 0) {
    //     this.manual.pageStart = 1;
    //   } else {
    //     this.manual.pageStart =
    //       (Number(search.parameters.page_number) - 1) * Number(search.parameters.records_per_page) + 1;
    //   }
    //   this.manual.pageEnd =
    //     (Number(search.parameters.page_number) - 1) * Number(search.parameters.records_per_page) +
    //     Number(search.parameters.records_per_page);
    //   if (this.manual.total <= this.manual.pageEnd) {
    //     this.manual.pageEnd = this.manual.total;
    //   }
    // });

    this.cprsService.getCurrentSearch().subscribe((search) => {
      this.current_search = search;

      // Assign specific date filters to the table and individual data-rows
      // get date picker info
      const date_picker_value = this.stateService.date_picker.value;

      let date_type_value = date_picker_value['date_type'] ? date_picker_value['date_type'] : null
      // "registration_date_as_date" is not utilized by the backend?
      if (date_type_value === 'registration_date_as_date') {
        date_type_value = 'registration_date';
      }

      const date_type_options = this.stateService.date_type_options; // for label lookup
      const date_type_label = date_type_options.find(o => o.value === date_picker_value['date_type'])?.text ?? 'Date';

      // setup table content
      this.content = search?.response.data.map((d) => {
        const table_row = this.getData(d);

        if (table_row['representative_date'] && date_type_value && date_type_value !== 'representative_date') {
          // source_date is under "meta_data"
          let date_picker_value_found = d.get(date_type_value)

          if (date_type_value === 'source_date') {
            date_picker_value_found = d.get('meta_data')['source_date'];
          }

          if (date_picker_value_found) {
            // access and change the column label
            const table_date_column = table_row['representative_date'];
            table_date_column.label = date_type_label;
            table_date_column.value = date_picker_value_found;

            const table_support = this.newTable.columns.find(c => c.columnKey === 'representative_date');
            if (table_support) {
              table_support.label = date_type_label;
            }
          }
        }

        if (date_type_value === 'representative_date') {
          const table_support = this.newTable.columns.find(c => c.columnKey === 'representative_date');
          if (table_support) {
            table_support.label = 'Date';
          }
        }

        return table_row;
      }) ?? [];

      this.manual.total = search.response.metadata.hit_count;
      if (search.parameters.page_number === 0) {
        this.manual.pageStart = 1;
      } else {
        this.manual.pageStart =
          (Number(search.parameters.page_number) - 1) * Number(search.parameters.records_per_page) + 1;
      }
      this.manual.pageEnd =
        (Number(search.parameters.page_number) - 1) * Number(search.parameters.records_per_page) +
        Number(search.parameters.records_per_page);
      if (this.manual.total <= this.manual.pageEnd) {
        this.manual.pageEnd = this.manual.total;
      }
      this.cprsService.setCurrentSearchResults('search_results');
    });

    this.cprsService.onFacet.subscribe(search => {
      if (!search) {
        return;
      }
      this.current_search = search;
      this.content = search?.response.data.map((d) => this.getData(d)) ?? [];

      this.manual.total = search.response.metadata.hit_count;
      if (search.parameters.page_number === 0) {
        this.manual.pageStart = 1;
      } else {
        this.manual.pageStart =
          (Number(search.parameters.page_number) - 1) * Number(search.parameters.records_per_page) + 1;
      }
      this.manual.pageEnd =
        (Number(search.parameters.page_number) - 1) * Number(search.parameters.records_per_page) +
        Number(search.parameters.records_per_page);
      if (this.manual.total <= this.manual.pageEnd) {
        this.manual.pageEnd = this.manual.total;
      }
      this.cprsService.setCurrentSearchResults('search_results');
    })
  }

  serverSideSort($sort: SortEvent) {
    if ($sort.direction === 'none') {
      this.stateService._state.get('sort_field')?.reset();
      this.stateService._state.get('sort_order')?.reset();
    } else {
      this.stateService._state.get('sort_field')?.setValue($sort.name);
      this.stateService._state.get('sort_order')?.setValue($sort.direction);
    }

    this.stateService.pagination.get('page_number')?.setValue(Number(1));

    this.cprsService.search(this.current_search?.type);
  }

  nextPage() {
    this.$page.number = this.$page.number + 1;
    this.pageChange(this.$page);
  }

  prevPage() {
    this.$page.number = this.$page.number - 1;
    this.pageChange(this.$page);
  }

  setViewMode(mode: 'grid' | 'table') {
    this.viewMode = mode;
    this.stateService._state.get('mode')?.setValue(mode);
  }

  getData(esr: ElasticSearchResult) {
    const highlights: ElasticSearchHighlight = esr.highlights;
    const label = highlights?.label;
    const value_to_find = highlights?.value_to_find;
    let typeOfWorkVal = esr.findFirst(['type_of_work_to_english', 'cc_type_of_work']);
    if (typeOfWorkVal === 'Cancelled Registrations') {
      typeOfWorkVal = typeOfWorkVal.slice(0, -1);
    }
    return {
      full_title: {
        value: esr.getTitle(true),
        url: esr.getHref(),
      },
      copyright_number: {
        /* eslint-disable-next-line no-nested-ternary */
        label: esr.getCopyrightNumberLabel(),
        value: esr.getCopyrightNumber(),
      },

      // this needs to have a custom label dependeant on record type
      representative_date: {
        label: esr.isRegistration() ? 'Date' : 'Date of Recordation',
        value: esr.get('representative_date'),
        visible: typeOfWorkVal === 'Cancelled Registration' ? null : (esr.isRecordation() || esr.isRegistration()),
      },
      acquisition_type: {
        value: esr.get('type_of_acquisition'),
        visible: esr.isAcquisition(),
      },
      type_of_work: {
        label: 'Type of Work',
        value: this.cprsService.toTitleCase(typeOfWorkVal),
      },

      // this also needs a custom label between Party 1 / Owner if GATT
      party_1: {
        label: esr.isGATT() ? 'Owner' : 'Party 1',
        value: esr.getPartyOne(),
      },
      publisher_name: {
        label: 'Publisher Name',
        value: esr.getPublisherName(),
        visible: esr.isAcquisition(),
      },
      claimant: {
        label: 'Claimant',
        value: esr.getClaimant(),
      },

      // this also needs a custom label between Party 2 / Author if GATT
      party_2: {
        label: esr.isGATT() ? 'Author' : 'Party 2',
        value: esr.getPartyTwo(),
      },
      service_request_number: {
        label: 'Service Request Number',
        value: esr.get('service_request_number'),
        visible: this.envService.internal,
      },

      saving: {
        label: '',
        value: false
      },

      highlights: {
        label,
        value: value_to_find,
      },

      simple_record: {
        value: esr.getSimpleRecord(esr),
      },
      control_number: {
        value: esr.get('control_number'),
      },
      type_of_record: {
        value: esr.get('type_of_record')
      }
    };
  }
}
