import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StateService } from '@src/app/cprs/state/state.service';
import { CdSelectionService } from '../../services/selection.service';
import { Page } from '@cop/design/services';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4

@Component({
  selector: '[cprsChildSelect]',
  template: `
    <form [formGroup]="childGroup">
      <cd-input-crs [inputClasses]="'fix-spacing'" [label]="uniqueCheckboxLabelId" [labelHidden]="true" formControlName="selected" (click)="onClick($event)"></cd-input-crs>
    </form>
  `,
})
export class ChildSelectComponent implements OnInit {
  @Input() cprsChildSelect: string | UntypedFormGroup;
  @Input() index: number;
  @Input() data: any;

  public absoluteIndex: any;

  uniqueCheckboxLabelId: string;

  public childGroup: UntypedFormGroup = new UntypedFormGroup({
    index: new UntypedFormControl(),
    selected: new UntypedFormControl(false),
    data: new UntypedFormControl(),
  });

  public selected: UntypedFormControl = new UntypedFormControl(false);

  constructor(
    public stateService: StateService,
    public cdSelectionService: CdSelectionService
  ) { }

  ngOnInit(): void {
    this.uniqueCheckboxLabelId = uuidv4();
    this.childGroup.valueChanges.subscribe(() => {
      const numSelectedOnPage = this.cdSelectionService.numSelectedOnPage(this.cprsChildSelect as string);
      const totalSelected = this.cdSelectionService.totalSelected(this.cprsChildSelect as string);
      const formGroup = this.cdSelectionService.getSelectionGroup(this.cprsChildSelect as string);

      if (!formGroup.get('selectAll').value && numSelectedOnPage > 0) {
        formGroup.get('selectAll').setValue(true);
        return;
      }

      if (formGroup.get('selectAll').value && numSelectedOnPage === 0 && totalSelected === 0) {
        formGroup.get('selectAll').setValue(false);
      }
    });

    const formGroup = this.cdSelectionService.getSelectionGroup(this.cprsChildSelect as string);

    let page_number = this.stateService.pagination.get('page_number')?.value;
    let records_per_page = this.stateService.pagination.get('rows_per_page')?.value;

    if (formGroup) {
      const pageControl: Page<any> = formGroup.get('page')?.value;
      if (pageControl) {
        page_number = pageControl.number + 1;
        records_per_page = pageControl.size;
      }
    }

    this.absoluteIndex = this.index + 1 + records_per_page * (page_number - 1);

    this.childGroup.get('index')?.setValue(this.absoluteIndex);
    this.childGroup.get('data')?.setValue(this.data);

    if (formGroup) {
      const current_items: UntypedFormGroup[] = formGroup.get('items')?.value;
      const duplicate = current_items.find(
        (grp) =>
          grp.get('index')?.value === this.absoluteIndex
      );
      if (duplicate) {
        this.childGroup = duplicate;
        this.childGroup.get('data')?.setValue(this.data);
        this.childGroup.get('selected')?.setValue(false);
      } else {
        current_items.push(this.childGroup);
        formGroup.get('items')?.setValue(current_items);
      }
    }
  }

  onClick($event: any) {
    const checkboxSelected = this.childGroup.get('selected')?.value;
    const totalSelected = this.cdSelectionService.totalSelected(this.cprsChildSelect as string);
    const selectionGroup: UntypedFormGroup = this.cdSelectionService.getSelectionGroup(this.cprsChildSelect as string);
    const SELECTION_LIMIT = selectionGroup.get('limit')?.value;

    if (totalSelected >= SELECTION_LIMIT && !checkboxSelected) {
      $event.preventDefault();
    }
  }

  get isDisabled(): boolean {
    return true;
  }
}
