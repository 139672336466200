import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Page, PageImpl } from '@cop/design/services';
import { BehaviorSubject } from 'rxjs';
import { CdTableData, CprsTableGrid } from '../../models/cprs-table-grid.interface';
import { PagingGotoComponent, SortEvent } from '@cop/design/table';
import { CdSelectionService } from '../../../selection/services/selection.service';
import { Router } from '@angular/router';
import { ParametersService } from '@src/app/cprs/services/parameters.service';

/*

  Generic Table Wrapper
    needs to be able to handle:
      - server side paging / sorting
      - client side paging / sorting
      - resizable column
      - loading

    big problem with our search results
      - we have dynamic labels in the grid view
      - but in table view we have static labels
      - maybe the best UX is the following:
      - grid has the dynamic labels
      - table has all of the labels,
      - table header: select index copyright_number ... registration ... recordation .. acquisition
      - could lead to a very long header but would make the most sense?

    we also need to support hyperlinking, so some advanced content support

    plus how do we allow for dynamic content projection underneath the search results?

    so whats our data structure look like for this?

    what we currently support:
      - paging (almost, client side done, server side TODO)
      - sorting (TODO)
      - dynamic labels for Grid
      - toggleable columns dependant on mode type
*/
@Component({
  selector: 'cprs-table-grid',
  templateUrl: './cprs-table-grid.component.html',
  styleUrls: ['./cprs-table-grid.component.scss'],
})
export class CprsTableGridComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('goToRef', { static: false }) goToRef: PagingGotoComponent;

  @ContentChild('header', { static: false }) headerTemplateRef: TemplateRef<any>;

  @ContentChild('sideColumn', { static: false }) sideColumnRef: TemplateRef<any>;

  @Input() interface: CprsTableGrid;

  @Input() mode: 'grid' | 'table' = 'table';

  @Input() sortType: 'client' | 'server' = 'client';

  @Input() pagingType: 'client' | 'server' = 'client';

  @Input() pageSizes: number[] = [10, 25, 50, 100];

  @Input() selectionKey: string;

  @Input() content: any[] = [];

  @Input() totalElements: number;

  @Input() showColumnFiltering: boolean = false;

  @Input() showPagingDisplay: boolean = false;

  @Input() showSimplePager: boolean = false;

  @Input() showModeToggle: boolean = false;

  @Input() showIndexColumn: boolean = true;
  @Input() showSelectControls: boolean = true;

  @Input() showHeaderOnEmpty: boolean = true;

  @Input() serverPage: BehaviorSubject<Page<any>>;

  @Input() serverSorting: boolean = false;

  @Input() showPagerOnly: boolean = false;

  @Output() sortChange = new EventEmitter<SortEvent>();

  @Output() pageChange = new EventEmitter<Page<any>>();

  @Output() viewModeChange = new EventEmitter<'grid' | 'table'>();

  public selectionForm: UntypedFormGroup;

  public clientPage: BehaviorSubject<Page<any>> = new BehaviorSubject(PageImpl.empty());

  public fakePage = PageImpl.empty();

  columnForm: UntypedFormGroup;
  gridColumnForm: UntypedFormGroup;
  tableColumnForm: UntypedFormGroup;

  visibleColumns: string[];
  modeColumns: any[];

  public manual = {
    pageStart: 0,
    pageEnd: 0,
    total: 0,
  };

  constructor(public router: Router, public selectionService: CdSelectionService, public parameterService: ParametersService) {
    this.fakePage.size = 10;
    this.fakePage.totalPages = 1;
    this.fakePage.first = true;
    this.fakePage.isLoaded = true;
    this.fakePage.numberOfElements = this.fakePage.size;
  }

  /*
    Prepare table data based on the interfae
      - CD3.0 requires a formgroup and visible columns
  */
  ngOnInit(): void {
    if (this.interface) {
      this.updateColumns();
    }

    if (this.selectionKey) {
      this.selectionForm = this.selectionService.getSelectionGroup(this.selectionKey);
    }

    if (this.pagingType === 'server') {
      this.serverPage.subscribe((page) => {
        if (this.selectionForm) {
          const pageForm = this.selectionForm.get('page');
          if (pageForm) {
            pageForm.setValue(page);
          }
        }
        this.fakePage.size = page.size;
        this.fakePage.number = page.number;
        this.fakePage.totalElements = page.totalElements;
        this.fakePage.totalPages = page.totalPages;
        this.updatePagingDisplay(page);
      });
    } else {
      this.clientPage.subscribe((page) => {
        this.updatePagingDisplay(page);
      });

      this.parameterService.route.queryParamMap.subscribe(queryParamMap => {
        const size = Number(queryParamMap.get('records_per_page') ?? 10);
        const number = Number(queryParamMap.get('page_number') ?? 0);

        this.fakePage.number = number;
        this.fakePage.size = size;
        this.goto(this.fakePage);
      })
    }
  }

  ngAfterViewInit(): void {
    this.goToRef.initPage();
  }

  isNextDisabled() {
    return (this.fakePage.number + 1) === this.fakePage.totalPages;
  }

  isPrevDisabled() {
    this.fakePage.number === 0 ? this.fakePage.first = true : this.fakePage.first = false;
    return this.fakePage.first || this.fakePage.number === 0;
  }

  get $page() {
    return this.pagingType === 'client' ? this.clientPage : this.serverPage;
  }

  updatePagingDisplay(page: Page<any>, constant = 0) {
    // TODO MANUAL
    this.manual.total = page.totalElements;
    if (page.number === 0) {
      this.manual.pageStart = 1;
    } else {
      this.manual.pageStart = (Number(page.number) + constant) * Number(page.size) + 1;
    }
    this.manual.pageEnd = (Number(page.number) - constant) * Number(page.size) + Number(page.size);
    if (this.manual.total <= this.manual.pageEnd) {
      this.manual.pageEnd = this.manual.total;
    }

    this.manual.pageEnd = 12;
  }

  updateColumns() {
    if (this.mode === 'grid') {
      const formGroup = new UntypedFormGroup({});
      const gridColumns = this.interface.columns
        .filter((c) => c.visibility.grid && c.visibility.visible)
        .map((c) => c.columnKey);
      this.interface.columns
        .filter((c) => c.visibility.grid)
        .forEach((c) => formGroup.addControl(c.columnKey, new UntypedFormControl(c.visibility.visible)));
      this.modeColumns = this.interface.columns.filter((c) => c.visibility.grid);

      this.gridColumnForm = formGroup;
      this.visibleColumns = gridColumns;

      this.gridColumnForm.valueChanges.subscribe((columns) => {
        this.visibleColumns = Object.keys(columns).filter((col) => columns[col] === true);
      });
    } else {
      const formGroup = new UntypedFormGroup({});
      const tableColumns = this.interface.columns
        .filter((c) => c.visibility.table && c.visibility.visible)
        .map((c) => c.columnKey);
      this.interface.columns
        .filter((c) => c.visibility.table)
        .forEach((c) => formGroup.addControl(c.columnKey, new UntypedFormControl(c.visibility.visible)));
      this.modeColumns = this.interface.columns.filter((c) => c.visibility.table);

      this.tableColumnForm = formGroup;
      this.visibleColumns = tableColumns;

      this.tableColumnForm.valueChanges.subscribe((columns) => {
        this.visibleColumns = Object.keys(columns).filter((col) => columns[col] === true);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['totalElements']) {
      this.fakePage.totalElements = changes['totalElements'].currentValue;
      this.fakePage.totalPages = Math.ceil(this.fakePage.totalElements / this.fakePage.size);

      const pageStart = this.fakePage.number * this.fakePage.size + 1;
      if (this.fakePage.totalElements < pageStart) {
        this.fakePage.number = this.fakePage.number - 1 > 0 ? this.fakePage.number - 1 : 0;
      }
      if (this.totalElements <= this.fakePage.numberOfElements) {
        this.fakePage.numberOfElements = this.fakePage.totalElements;
      }
      this.clientPage.next(this.fakePage);
      this.serverPage?.next(this.fakePage);
    }

    if (changes && changes['mode']) {
      this.updateColumns();
    }
  }

  goto($event: any) {
    this.fakePage = $event;
    this.fakePage.numberOfElements = $event.size;
    this.fakePage.totalElements = $event.totalElements;
    this.fakePage.totalPages = Math.ceil(this.fakePage.totalElements / $event.size);
    this.fakePage.first = $event.number <= 0;
    this.fakePage.last = $event.number + 1 >= this.fakePage.totalPages;
    this.fakePage.content = [];
    this.fakePage.isLoaded = true;

    if (this.pagingType === 'client') {
      if (this.content.length < this.fakePage.numberOfElements) {
        this.fakePage.numberOfElements = this.content.length;
        this.triggerPageChangeEmit(this.fakePage)
      }

      this.selectionForm.get('page')?.setValue(this.fakePage);
      this.clientPage.next(this.fakePage);

      this.parameterService.addQueryParameters({
        page_number: this.fakePage.number,
        records_per_page: this.fakePage.size
      });
    }

    this.pageChange.emit($event);
  }

  triggerPageChangeEmit(event: any) {
    this.pageChange.emit(event);
  }

  prevPage() {
    this.fakePage.number = this.fakePage.number - 1;
    this.goto(this.fakePage);
  }

  nextPage() {
    this.fakePage.number = this.fakePage.number + 1;
    this.goto(this.fakePage);
  }

  sortTable($event: any) {
    if (this.sortType === 'client') {
      this.genericSort($event);
    } else {
      this.sortChange.emit($event);
    }
  }

  setViewMode(mode: 'grid' | 'table') {
    this.mode = mode;
    this.viewModeChange.next(mode);
  }

  public genericSort($event: SortEvent) {
    this.content.sort((a: CdTableData, b: CdTableData) => {
      let comparison = 0;

      const valueA = a[$event.name]['value'];
      const valueB = b[$event.name]['value'];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // compare string values
        comparison = valueA.localeCompare(valueB);
      } else if (valueA instanceof Array && valueB instanceof Array) {
        // compare arrays by JSON
        const jsonA = JSON.stringify(valueA);
        const jsonB = JSON.stringify(valueB);
        comparison = jsonA.localeCompare(jsonB);
      } else {
        // default
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        } else {
          comparison = 0;
        }
      }

      return $event.direction === 'asc' ? comparison : -comparison;
    });
  }
}
